import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (!error) {
        setSession(data.session);
        setUser(data.session?.user ?? null);
        if (data.session?.user) {
          checkUser(data.session.user.id);
        }
      }
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setSession(session);
        setUser(session?.user ?? null);

        if (event === 'SIGNED_OUT') {
          setSession(null);
          setUser(null);
          setUserData(null);
        }

        if (session) {
          checkUser(session.user.id);
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const checkUser = async (userId) => {
    // First, fetch the user's plan_id
    const { data: planData, error: planError } = await supabase
      .from('users')
      .select('plan_id')
      .eq('id', userId)
      .single();

    if (planError) {
      console.error('Error fetching user plan:', planError);
      return;
    }

    const isFreeTier = planData.plan_id === 'fd5938fb-a785-4330-9e50-29bec31c6533';

    // Conditionally select fields based on the plan
    const selectFields = isFreeTier
      ? 'id, name, avatar_url, num_requests, last_reset, first_login, plan_id'
      : 'id, name, avatar_url, api_key, num_requests, last_reset, first_login, plan_id';

    const { data, error: selectError } = await supabase
      .from('users')
      .select(selectFields)
      .eq('id', userId)
      .single();

    if (selectError && selectError.code !== 'PGRST116') {
      console.error('Error fetching user:', selectError);
    }

    if (!data) {
      // User doesn't exist, insert their data
      const newUser = {
        id: userId,
        name: user.user_metadata.full_name || null,
        avatar_url: user.user_metadata.avatar_url || null,
        api_key: isFreeTier ? null : uuidv4(),
        num_requests: 50,
        last_reset: new Date().toISOString(),
        first_login: true,
        plan_id: 'fd5938fb-a785-4330-9e50-29bec31c6533', // Free Tier plan ID
      };

      const { error: insertError } = await supabase
        .from('users')
        .insert([newUser]);

      if (insertError) {
        console.error('Error inserting user:', insertError);
      } else {
        setUserData(newUser);
      }
    } else {
      setUserData(data);
    }
  };

  const value = {
    session,
    user,
    userData,
    setUserData
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faGlobe, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

const IPInfoCard = ({ ipInfo }) => (
  <div className="card bg-base-100 shadow-xl mb-4 w-full">
    <div className="card-body">
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faGlobe} className="mr-2 text-base-content" />
        <h3 className="card-title text-base-content">{ipInfo.ip}</h3>
      </div>
      <div className="space-y-1">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-base-content" />
          <p className="text-base-content">{ipInfo.city}, {ipInfo.country} ({ipInfo.countryCode})</p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faBuilding} className="mr-2 text-base-content" />
          <p className="text-base-content">{ipInfo.org}</p>
        </div>
      </div>
    </div>
  </div>
);

const NetworkInformation = ({ scanData }) => {
  const renderGeoData = () => {
    if (!scanData.geo_data) {
      return <p className="text-base-content opacity-70">No geolocation data available.</p>;
    }

    let geoDataArray;
    try {
      geoDataArray = typeof scanData.geo_data === 'string' ? JSON.parse(scanData.geo_data) : scanData.geo_data;
    } catch (error) {
      console.error("Error parsing geo_data:", error);
      return <p className="text-base-content opacity-70">Error parsing geolocation data</p>;
    }

    if (!Array.isArray(geoDataArray)) {
      geoDataArray = [geoDataArray];
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        {geoDataArray.map((ipInfo, index) => (
          <IPInfoCard key={index} ipInfo={ipInfo} />
        ))}
      </div>
    );
  };

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faNetworkWired} className="mr-2" /> Network Information
        </h2>
        {renderGeoData()}
      </div>
    </div>
  );
};

export default NetworkInformation;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faExternalLinkAlt, faFileAlt, faInfoCircle, faListAlt } from '@fortawesome/free-solid-svg-icons';

const HTMLAnalysis = ({ htmlAnalysis }) => {
  if (!htmlAnalysis || typeof htmlAnalysis !== 'object' || !htmlAnalysis.analysis) {
    return renderNoAnalysisAvailable();
  }

  let { analysis, risk_score, classification } = htmlAnalysis;

  // Check if it's the "No HTML content" case
  if (analysis.summary === "No HTML content found on the page." &&
      !analysis.suspicious_urls &&
      analysis.additional_factors === "The page contains no HTML to analyze." &&
      !analysis.deobfuscated_context &&
      !analysis.forms_and_data_handling) {
    return renderNoHtmlContent();
  }

  // Function to clean the JSON string
  const cleanJsonString = (str) => {
    str = str.replace(/^\uFEFF/, '');
    str = str.replace(/\\([^"\\\/bfnrtu])/g, '$1');
    return str;
  };

  let parsedAnalysis;
  try {
    if (typeof analysis === 'string') {
      // Handle older format
      parsedAnalysis = JSON.parse(cleanJsonString(JSON.stringify(htmlAnalysis)));
      if (parsedAnalysis.analysis) {
        const innerJsonMatch = parsedAnalysis.analysis.match(/```json\n([\s\S]*?)\n```/);
        if (innerJsonMatch) {
          parsedAnalysis = JSON.parse(cleanJsonString(innerJsonMatch[1]));
        } else {
          throw new Error("Inner JSON not found");
        }
      }
      analysis = parsedAnalysis.analysis;
      risk_score = parsedAnalysis.risk_score;
      classification = parsedAnalysis.classification;
    }
  } catch (error) {
    console.error("Error parsing HTML analysis:", error);
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">
            <FontAwesomeIcon icon={faFileCode} className="mr-2" aria-hidden="true" /> HTML Analysis
          </h2>
          <div className="alert alert-error">
            <div>
              <h3 className="font-bold">Error Parsing HTML Analysis</h3>
              <div className="text-sm">
                There was an error processing the HTML analysis data. This could be due to an unexpected format or corrupted data. If this issue persists, please contact{' '}
                <a 
                  href="mailto:support@aiurl.io" 
                  className="link link-primary"
                  aria-label="Email support at support@aiurl.io"
                >
                  support@aiurl.io
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderContent = (content) => {
    // Ensure content is a string
    if (typeof content !== 'string') {
      console.warn('renderContent received non-string content:', content);
      return String(content);
    }

    // Remove backticks from the content
    content = content.replace(/`/g, '');
    
    // Split content by URLs within parentheses
    const parts = content.split(/(\([^()]*https?:\/\/[^()]+\))/g);
    return parts.map((part, index) => {
      if (part.match(/^\([^()]*https?:\/\/[^()]+\)$/)) {
        const url = part.match(/(https?:\/\/[^()]+)/)[0];
        return (
          <span key={index}>
            ({' '}
            <kbd className="kbd kbd-sm font-mono text-xs px-2 py-1 mx-1 bg-base-300 text-base-content break-all">
              {url}
            </kbd>
            {' '})
          </span>
        );
      }
      return part;
    });
  };

  const renderSuspiciousUrls = (urls) => {
    if (typeof urls === 'string') {
      urls = [urls]; // Convert single string to array
    }
    if (!Array.isArray(urls)) return null;
    
    return urls.map((url, index) => {
      // Add 'https://' to URLs starting with '//'
      const formattedUrl = url.startsWith('//') ? `https:${url}` : url;
      return (
        <li key={index} className="mb-1">
          <kbd className="kbd kbd-sm font-mono text-xs px-2 py-1 break-all">
            {formattedUrl}
          </kbd>
        </li>
      );
    });
  };

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">
          <FontAwesomeIcon icon={faFileCode} className="mr-2" aria-hidden="true" /> HTML Analysis
        </h2>
        <div className="space-y-6">
          <div className="alert alert-info">
            <div>
              <h3 className="font-bold">HTML Analysis Summary</h3>
              <div className="text-sm break-words">{renderContent(analysis.summary)}</div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Analysis Details</h3>
            <ul className="space-y-4">
              <li className="flex">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mt-1 mr-2 text-primary flex-shrink-0" aria-hidden="true" />
                <div className="flex-grow">
                  <strong>Suspicious URLs:</strong>
                  <ul className="list-disc list-inside mt-2">
                    {renderSuspiciousUrls(analysis.suspicious_urls)}
                  </ul>
                </div>
              </li>
              <li className="flex">
                <FontAwesomeIcon icon={faFileAlt} className="mt-1 mr-2 text-secondary flex-shrink-0" aria-hidden="true" />
                <div className="flex-grow"><strong>Deobfuscated Content:</strong> <span className="break-words">{renderContent(analysis.deobfuscated_context)}</span></div>
              </li>
              <li className="flex">
                <FontAwesomeIcon icon={faInfoCircle} className="mt-1 mr-2 text-accent flex-shrink-0" aria-hidden="true" />
                <div className="flex-grow"><strong>Forms and Data Handling:</strong> <span className="break-words">{renderContent(analysis.forms_and_data_handling)}</span></div>
              </li>
              <li className="flex">
                <FontAwesomeIcon icon={faListAlt} className="mt-1 mr-2 text-success flex-shrink-0" aria-hidden="true" />
                <div className="flex-grow"><strong>Additional Factors:</strong> <span className="break-words">{renderContent(analysis.additional_factors)}</span></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderNoAnalysisAvailable = () => (
  <div className="card bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title">
        <FontAwesomeIcon icon={faFileCode} className="mr-2" aria-hidden="true" /> HTML Analysis
      </h2>
      <div className="alert alert-info">
        <div>
          <h3 className="font-bold">HTML Analysis Not Available</h3>
          <div className="text-sm">
            HTML analysis was not performed for this URL. This may be due to the site being unavailable or not containing analyzable HTML content.
          </div>
        </div>
      </div>
    </div>
  </div>
);

const renderNoHtmlContent = () => (
  <div className="card bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title">
        <FontAwesomeIcon icon={faFileCode} className="mr-2" aria-hidden="true" /> HTML Analysis
      </h2>
      <div className="alert alert-info">
        <div>
          <h3 className="font-bold">No HTML Content</h3>
          <div className="text-sm">
            No HTML content was found on the page. This could be due to a blank page, a non-HTML resource, or a server error.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HTMLAnalysis;

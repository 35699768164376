import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { AuthProvider, AuthContext } from './utils/AuthContext';
import ResultsPage from './components/ResultsPage';
import RecentScans from './components/RecentScans';
import SettingsPage from './components/SettingsPage';
import ProtectedRoute from './components/ProtectedRoute';
import NotFoundPage from './components/NotFoundPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import HowItWorks from './components/HowItWorks';
import TermsOfServicePage from './components/TermsOfServicePage';
import PricingPage from './components/PricingPage';
import FirstTimeLoginModal from './components/FirstTimeLoginModal';
import { supabase } from './utils/supabaseClient';
import { ThemeProvider, ThemeContext } from './utils/ThemeContext';

// New component to handle routes and FirstTimeLoginModal
const AppRoutes = () => {
  const location = useLocation();
  const showErrorInfo = new URLSearchParams(location.search).get('showErrorInfo') === 'true';
  const { isFirstLogin, setIsFirstLogin, user, userData, setUserData } = useContext(AuthContext);
  const [isFirstTimeLoginModalOpen, setIsFirstTimeLoginModalOpen] = useState(false);

  useEffect(() => {
    if (user && isFirstLogin) {
      setIsFirstTimeLoginModalOpen(true);
    }
  }, [user, isFirstLogin]);

  const handleFirstLoginComplete = async (name, avatarUrl) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ 
          name: name,
          avatar_url: avatarUrl,
          first_login: false 
        })
        .eq('id', user.id);

      if (error) throw error;

      setIsFirstLogin(false);
      setUserData(prevData => ({...prevData, name, avatar_url: avatarUrl, first_login: false}));
      setIsFirstTimeLoginModalOpen(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/results/:jobId" element={<ResultsPage showErrorInfo={showErrorInfo} />} />
        <Route path="/recent-scans" element={<RecentScans />} />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/privacy" element={<PrivacyPolicyPage />} /> // Add the new route for the Privacy Policy page
        <Route path="/terms" element={<TermsOfServicePage />} /> {/* Add this new route */}
        <Route path="/how-it-works" element={<HowItWorks />} /> {/* Add the new route for HowItWorks */}
        <Route path="/pricing" element={<PricingPage />} /> {/* Add this new route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <FirstTimeLoginModal
        isOpen={isFirstTimeLoginModalOpen}
        onClose={() => setIsFirstTimeLoginModalOpen(false)}
        onComplete={handleFirstLoginComplete}
        userData={userData}
      />
    </>
  );
};

const ThemedApp = () => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <ThemedApp />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faClock, faInfoCircle, faCopy, faExclamationTriangle, faUser, faEye, faEyeSlash, faTags } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ScanHero = ({ scanData, copyToClipboard, scanUser, showErrorInfo }) => {
  const navigate = useNavigate();

  const getRiskAssessment = (riskScore, processingStatus, status) => {
    if (processingStatus === "ERROR") {
      return {
        title: 'Error Occurred',
        color: 'text-error',
        bgColor: 'bg-error/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-error',
        status: status // Add this line
      };
    } else if (riskScore === null || riskScore === undefined) {
      return {
        title: 'Unknown',
        color: 'text-info',
        bgColor: 'bg-info/20',
        icon: faInfoCircle,
        scoreColor: 'text-info'
      };
    } else if (riskScore > 75) {
      return {
        title: 'High Risk Detected',
        color: 'text-error',
        bgColor: 'bg-error/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-error'
      };
    } else if (riskScore >= 35) {
      return {
        title: 'Moderate Risk Detected',
        color: 'text-warning',
        bgColor: 'bg-warning/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-warning'
      };
    } else {
      return {
        title: 'Low Risk Detected',
        color: 'text-success',
        bgColor: 'bg-success/20',
        icon: faInfoCircle,
        scoreColor: 'text-success'
      };
    }
  };

  // Updated getRiskScore function
  const getRiskScore = useCallback(() => {
    if (scanData.ai_analysis && typeof scanData.ai_analysis === 'object') {
      // First, try to get the risk_score directly from ai_analysis
      if (typeof scanData.ai_analysis.risk_score === 'number') {
        return scanData.ai_analysis.risk_score;
      }

      // If not found, try to parse the analysis string
      if (typeof scanData.ai_analysis.analysis === 'string') {
        try {
          const analysisString = scanData.ai_analysis.analysis.replace(/```json|```/g, '').trim();
          const parsedAnalysis = JSON.parse(analysisString);
          if (typeof parsedAnalysis.risk_score === 'number') {
            return parsedAnalysis.risk_score;
          }
        } catch (error) {
          console.error("Error parsing ai_analysis:", error);
        }
      }
    }
    // If we couldn't get a risk score from ai_analysis, fall back to final_score
    return scanData.final_score;
  }, [scanData]);

  const riskScore = getRiskScore();
  const riskAssessment = getRiskAssessment(riskScore, scanData.processing_status, scanData.status);

  const getResizedImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return null;
    
    // The originalUrl should be in the format:
    // https://imagedelivery.net/<ACCOUNT_HASH>/<IMAGE_ID>
    
    // Append the 'thumbnail' variant
    return `${originalUrl}/fullsize`;
  }, []);

  useEffect(() => {
    //console.log('ScanHero received scan user data:', scanUser);
    //console.log('ScanHero received scan user ID:', scanUser?.id);
  }, [scanUser]);

  const handleTagClick = useCallback((tag) => {
    navigate(`/recent-scans?tag=${encodeURIComponent(tag)}`);
  }, [navigate]);

  const renderTags = (tags) => {
    if (!tags || tags.length === 0) return null;
    return (
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2 flex items-center">
          <FontAwesomeIcon icon={faTags} className="mr-2" />
          Tags
        </h3>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span 
              key={index} 
              className="badge badge-outline cursor-pointer hover:bg-base-300"
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    );
  };

  let aiAnalysisTags = [];
  if (scanData.ai_analysis) {
    if (typeof scanData.ai_analysis === 'object' && Array.isArray(scanData.ai_analysis.tags)) {
      // New format
      aiAnalysisTags = scanData.ai_analysis.tags;
    } else if (typeof scanData.ai_analysis.analysis === 'string') {
      // Old format
      try {
        const parsedAiAnalysis = JSON.parse(scanData.ai_analysis.analysis.replace(/```json|```/g, ''));
        aiAnalysisTags = parsedAiAnalysis.tags || [];
      } catch (error) {
        console.error("Error parsing ai_analysis for tags:", error);
      }
    }
  }

  const isAlive = (status) => {
    if (typeof status === 'boolean') {
      return status;
    }
    return status === 'alive' || status === 'Alive' || status === true || status === 'true';
  };

  return (
    <div className={`card bg-base-100 shadow-xl mb-6 ${riskAssessment.bgColor}`}>
      <div className="card-body">
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="card-title text-2xl">Scan Results</h2>
            </div>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faGlobe} className="text-base-content opacity-70 mr-2" aria-hidden="true" />
              <span className="text-base-content break-all">
                {scanData.original_url}
              </span>
              <button
                onClick={() => copyToClipboard(scanData.original_url)}
                className="btn btn-ghost btn-sm ml-2"
                aria-label="Copy URL to clipboard"
              >
                <FontAwesomeIcon icon={faCopy} aria-hidden="true" />
              </button>
            </div>
            
            {/* Add the title here */}
            {scanData.title && (
              <div className="mb-2 text-lg font-semibold">
                <span className="text-base-content opacity-70">Title: </span>
                {scanData.title}
              </div>
            )}
            
            {/* Avatar, user name, and scan details */}
            <div className="flex items-center mt-4">
              {scanUser && scanUser.avatar_url ? (
                <img 
                  src={scanUser.avatar_url} 
                  alt={`${scanUser.name || 'User'}'s avatar`}
                  className="w-8 h-8 rounded-full mr-2"
                />
              ) : (
                <div className="avatar placeholder">
                  <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
                    <FontAwesomeIcon icon={faUser} className="text-base-content" />
                  </div>
                </div>
              )}
              <div className="flex flex-col">
                <span className="text-sm font-bold text-base-content">
                  Scanned by: {scanUser?.name || 'Anonymous User'}
                </span>
                <span className="text-xs text-base-content opacity-70 flex items-center">
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" aria-hidden="true" />
                  Analyzed on: {new Date(scanData.timestamp).toLocaleString()} UTC
                  {scanData.time_to_complete && (
                    <>
                      <span className="ml-4">
                        <FontAwesomeIcon icon={faClock} className="mr-2" aria-hidden="true" />
                        Analysis time: {scanData.time_to_complete}
                      </span>
                    </>
                  )}
                  {/* Add the private/public indicator */}
                  <span className="ml-2 flex items-center">
                    <FontAwesomeIcon
                      icon={scanData.is_private ? faEyeSlash : faEye}
                      className={`mr-1 ${scanData.is_private ? 'text-base-content opacity-50' : 'text-success'}`}
                      aria-hidden="true"
                    />
                    {scanData.is_private ? 'Private' : 'Public'}
                  </span>
                </span>
              </div>
            </div>
            
            {/* Add the tags section */}
            {renderTags(aiAnalysisTags)}
          </div>
          
          {/* Screenshot */}
          {scanData.screenshot_url && (
            <div className="mt-4 md:mt-0 md:ml-4 flex-shrink-0">
              <img
                src={getResizedImageUrl(scanData.screenshot_url)}
                alt="Website Screenshot"
                className="w-full md:w-48 h-36 object-cover rounded-md"
              />
            </div>
          )}
        </div>
      </div>
      
      {/* Risk score, status, and risk assessment */}
      <div className="card-body bg-base-200">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-base-content mb-2">Risk Score</h3>
            <div className={`text-3xl font-bold ${riskAssessment.scoreColor}`} aria-label={`Risk score: ${riskScore === null || riskScore === undefined ? 'Not Available' : riskScore}`}>
              {riskScore === null || riskScore === undefined ? 'N/A' : riskScore}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-base-content mb-2">Status</h3>
            {scanData.processing_status === "ERROR" ? (
              <div className="text-lg font-semibold text-error">
                Error: {scanData.status || 'Unknown'}
              </div>
            ) : (
              <div className={`text-lg font-semibold ${
                isAlive(scanData.alive) ? 'text-success' : 'text-error'
              }`}>
                {isAlive(scanData.alive) ? 'Site was Live' : 'Site is Down'}
              </div>
            )}
          </div>
          {showErrorInfo ? (
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-semibold text-base-content mb-2">Error Details</h3>
              <div className="text-sm text-error text-center">
                {scanData.status || 'Unknown error'}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-semibold text-base-content mb-2">Risk Assessment</h3>
              <div className={`text-lg font-semibold ${riskAssessment.color} flex items-center`}>
                <FontAwesomeIcon icon={riskAssessment.icon} className="mr-2" aria-hidden="true" />
                {riskAssessment.title}
              </div>
              {riskAssessment.status && (
                <div className="text-sm text-base-content opacity-70 mt-1">
                  {riskAssessment.status}
                </div>
              )}
            </div>
          )}
        </div>
        
        {showErrorInfo && scanData.error && (
          <div className="mt-4 p-4 bg-error text-error-content rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Error Details</h3>
            <div className="text-sm whitespace-pre-wrap">
              {scanData.error}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScanHero;
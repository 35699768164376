import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faList, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FormDataDisplay from './FormDataDisplay';

// Register the HTML language
SyntaxHighlighter.registerLanguage('html', html);

const RawHTML = ({ rawHtml, formDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('formData');
  const [parsedFormDetails, setParsedFormDetails] = useState([]);

  useEffect(() => {
    if (formDetails) {
      try {
        const parsed = JSON.parse(formDetails);
        setParsedFormDetails(parsed);
        setActiveTab('formData');
      } catch (error) {
        console.error('Error parsing form details:', error);
        setActiveTab('rawHtml');
      }
    } else {
      setActiveTab('rawHtml');
    }
  }, [formDetails]);

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faFileCode} className="mr-2" /> HTML Content
        </h2>
        <div className="tabs tabs-boxed mb-4">
          {parsedFormDetails.length > 0 && (
            <a 
              className={`tab ${activeTab === 'formData' ? 'tab-active' : ''}`}
              onClick={() => setActiveTab('formData')}
            >
              <FontAwesomeIcon icon={faList} className="mr-2" />
              Form Data ({parsedFormDetails.length})
            </a>
          )}
          <a 
            className={`tab ${activeTab === 'rawHtml' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('rawHtml')}
          >
            <FontAwesomeIcon icon={faFileCode} className="mr-2" />
            Raw HTML
          </a>
        </div>
        {activeTab === 'formData' && parsedFormDetails.length > 0 ? (
          <FormDataDisplay formDetails={parsedFormDetails} />
        ) : (
          <div className="relative overflow-hidden" style={{ maxHeight: isExpanded ? 'none' : '300px' }}>
            <SyntaxHighlighter 
              language="html"
              style={atomOneDark}
              customStyle={{
                margin: 0,
                padding: '1em',
                backgroundColor: 'transparent',
              }}
            >
              {rawHtml}
            </SyntaxHighlighter>
            {!isExpanded && (
              <div 
                className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-base-100 to-transparent"
              />
            )}
          </div>
        )}
        {activeTab === 'rawHtml' && (
          <div className="card-actions justify-end">
            <button 
              className="btn btn-primary"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <>
                  <FontAwesomeIcon icon={faChevronUp} className="mr-2" />
                  Show Less
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                  Show More
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RawHTML;

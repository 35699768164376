import React, { useState, useContext, useCallback, useEffect } from 'react';
import { AuthContext } from '../utils/AuthContext';
import Header from './Header';
import Footer from './Footer';
import SettingsNav from './SettingsNav';
import UserDetailsSection from './UserDetailsSection';
import APIKeySection from './APIKeySection';
import WebhookSection from './WebhookSection';
import SubscriptionManager from './SubscriptionManager';
import LoginModal from './LoginModal';
import { Helmet } from 'react-helmet-async';
import ReleaseNotes from './ReleaseNotes';

const SettingsPage = () => {
  const [activePage, setActivePage] = useState('details');
  const [navKey, setNavKey] = useState(0);
  const { session, userData, setUserData } = useContext(AuthContext); // Add setUserData here
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(!session);

  const isFreeTier = userData?.plan_id === 'fd5938fb-a785-4330-9e50-29bec31c6533';
  const isBasicOrHigher = ['ad570897-fec6-4063-a005-460c41dd5300', '3ab56f81-3f40-460c-a85e-cbcf94cf675d', 'd43da4a9-d701-447c-959f-cd1affd2561e'].includes(userData?.plan_id);
  const isEnterprise = userData?.plan_id === 'd43da4a9-d701-447c-959f-cd1affd2561e';

  useEffect(() => {
    setNavKey(prevKey => prevKey + 1);
  }, [activePage]);

  const handlePageChange = useCallback((page) => {
    setActivePage(page);
  }, []);

  const renderActivePage = () => {
    switch (activePage) {
      case 'details':
        return <UserDetailsSection userData={userData} setUserData={setUserData} />;
      case 'api':
        return <APIKeySection userData={userData} isDisabled={!isBasicOrHigher} />;
      case 'webhook':
        return <WebhookSection userData={userData} isDisabled={!isEnterprise} />;
      case 'subscription':
        return <SubscriptionManager />;
      case 'release-notes':
        return <ReleaseNotes />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Settings</title>
        <meta name="description" content="Manage your account settings, API keys, webhooks, and subscription for the AI URL Scanner." />
        <meta name="keywords" content="URL scanner, settings, account settings, API keys, webhooks, subscription management" />
      </Helmet>

      <Header />
      <main className="container mx-auto py-10 px-4 sm:px-6 lg:px-8 flex-grow">
        <h1 className="sr-only">Settings Page</h1>
        <div className="flex flex-col md:flex-row gap-8">
          {/* Mobile dropdown */}
          <div className="md:hidden mb-4">
            <select
              value={activePage}
              onChange={(e) => handlePageChange(e.target.value)}
              className="select select-bordered w-full"
              aria-label="Select settings page"
            >
              <option value="details">User Details</option>
              <option value="api">API Key</option>
              <option value="webhook">Webhooks</option>
              <option value="subscription">Subscription</option>
              <option value="release-notes">Release Notes</option>
            </select>
          </div>

          {/* Desktop sidebar */}
          <div className="hidden md:block w-48">
            <nav aria-label="Settings navigation">
              <SettingsNav key={navKey} activePage={activePage} setActivePage={handlePageChange} />
            </nav>
          </div>

          {/* Main content */}
          <div className="flex-1 bg-base-200 p-6 rounded-lg shadow-md">
            {renderActivePage()}
          </div>
        </div>
      </main>
      <Footer />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSwitchToSignup={() => {
          setIsLoginModalOpen(false);
          // Add logic to switch to signup modal if needed
        }}
      />
    </div>
  );
};

export default SettingsPage;

import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items }) => {
  return (
    <div className="text-sm breadcrumbs mb-6 pb-4">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {index === items.length - 1 ? (
              <span className="text-base-content">{item.label}</span>
            ) : (
              <Link to={item.href} className="text-base-content/70 hover:text-base-content">
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;

import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const SecurityInfo = ({ scanData }) => {
  const navigate = useNavigate();

  const renderSSLDetails = (certDetails) => {
    if (!certDetails) {
      return <p className="text-base-content opacity-70">No SSL/TLS details available</p>;
    }

    let parsedCertDetails;
    try {
      parsedCertDetails = typeof certDetails === 'string' ? JSON.parse(certDetails) : certDetails;
    } catch (error) {
      console.error("Error parsing cert_details:", error);
      return <p className="text-base-content opacity-70">Error parsing SSL/TLS details</p>;
    }

    return (
      <div className="space-y-2">
        <p className="text-base-content">
          <strong>Issuer:</strong> {parsedCertDetails.issuer}
        </p>
        <p className="text-base-content">
          <strong>Subject:</strong> {parsedCertDetails.subject}
        </p>
        <p className="text-base-content">
          <strong>Valid From:</strong> {parsedCertDetails.valid_from}
        </p>
        <p className="text-base-content">
          <strong>Valid To:</strong> {parsedCertDetails.valid_to}
        </p>
        <p className="text-base-content break-words">
          <strong>Serial Number:</strong> {parsedCertDetails.serial_number}
        </p>
      </div>
    );
  };

  const renderJARMInfo = (jarmFingerprint) => {
    if (!jarmFingerprint) {
      return <p className="text-base-content opacity-70">No JARM fingerprint available</p>;
    }

    const jarmMatch = jarmFingerprint.match(/JARM:\s*([a-f0-9]+)/i);
    let jarmHash = jarmMatch ? jarmMatch[1] : 'N/A';

    // Check if the JARM hash is all zeros
    if (jarmHash === '00000000000000000000000000000000000000000000000000000000000000') {
      jarmHash = 'N/A';
    }

    return (
      <div>
        <p className="text-base-content break-words">
          <strong>JARM Fingerprint:</strong>{' '}
          {jarmHash === 'N/A' ? (
            jarmHash
          ) : (
            <RouterLink
              to={`/recent-scans?jarm=${encodeURIComponent(jarmHash)}`}
              className="link link-primary"
              aria-label={`Search for scans with JARM fingerprint ${jarmHash}`}
            >
              {jarmHash}
            </RouterLink>
          )}
        </p>
      </div>
    );
  };

  const getSafeBrowsingColor = (status) => {
    if (status && status.toUpperCase() === 'UNSAFE') return 'text-error';
    if (status && status.toUpperCase() === 'SAFE') return 'text-success';
    return 'text-base-content opacity-50';
  };

  const handleJarmClick = useCallback((jarmHash) => {
    navigate(`/recent-scans?jarm=${encodeURIComponent(jarmHash)}`);
  }, [navigate]);

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faShieldAlt} className="mr-2" aria-hidden="true" /> Security Information
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-base-content opacity-70">SafeBrowsing Status</p>
            <p className={`text-2xl font-bold ${getSafeBrowsingColor(scanData.safebrowsing)}`} aria-label={`SafeBrowsing Status: ${scanData.safebrowsing || 'Not Available'}`}>
              {scanData.safebrowsing || 'N/A'}
            </p>
          </div>
          <div>
            <p className="text-base-content opacity-70">Web Server</p>
            <p className="text-2xl font-bold text-base-content">{scanData.web_server || 'N/A'}</p>
          </div>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold text-base-content mb-2">
            <FontAwesomeIcon icon={faLock} className="mr-2" aria-hidden="true" /> SSL/TLS Details
          </h3>
          <div className="pl-6 space-y-2">
            {renderSSLDetails(scanData.cert_details)}
            {renderJARMInfo(scanData.jarm_fingerprint)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityInfo;
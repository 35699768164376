import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLink, faInfoCircle, faShieldAlt, faBolt } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';

const FirstTimeLoginModal = ({ isOpen, onClose, onComplete, userData }) => {
  const [name, setName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { setUserData } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      setName(userData.name || '');
      setAvatarUrl(userData.avatar_url || '');
    }
  }, [userData]);

  const handleNextStep = () => {
    if (name.trim()) {
      setStep(2);  // Move to the next step without calling onComplete
    } else {
      alert("Please enter your name to continue.");
    }
  };

  const isFreeTier = userData?.plan_id === 'fd5938fb-a785-4330-9e50-29bec31c6533';

  const renderApiKey = () => {
    if (!userData) return null;

    return (
      <div className={`${isFreeTier ? 'opacity-50' : ''}`}>
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Your API Key</h3>
        <p className="font-mono text-sm text-gray-700 dark:text-gray-300">
          {isFreeTier ? '••••••••••••••••' : (userData.api_key || 'Not available')}
        </p>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {isFreeTier 
            ? "API access is not available in the Free tier. Upgrade for API access." 
            : "Keep this key safe and don't share it with others."}
        </p>
      </div>
    );
  };

  const handleComplete = async () => {
    await updateFirstLoginState();
    onComplete(name, avatarUrl);
    navigate('/');
  };

  const handleViewPricing = async () => {
    await updateFirstLoginState();
    onClose();
    navigate('/pricing');
  };

  const updateFirstLoginState = async () => {
    const { error } = await supabase
      .from('users')
      .update({ first_login: false })
      .eq('id', userData.id);

    if (error) {
      console.error('Error updating first_login state:', error);
    } else {
      setUserData(prevData => ({ ...prevData, first_login: false }));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 dark:bg-gray-900 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Welcome to AI URL Scanner!</h3>
            <div className="mt-2">
              {step === 1 ? (
                <div className="space-y-4">
                  <h4 className="text-md font-medium text-gray-700 dark:text-gray-300">Let's set up your profile</h4>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      <FontAwesomeIcon icon={faUser} className="mr-2" />Your Name
                    </label>
                    <input 
                      type="text"
                      className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter your name" 
                      value={name} 
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      <FontAwesomeIcon icon={faLink} className="mr-2" />Avatar URL (optional)
                    </label>
                    <input 
                      type="text"
                      className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter avatar URL" 
                      value={avatarUrl} 
                      onChange={(e) => setAvatarUrl(e.target.value)}
                    />
                  </div>
                  {avatarUrl && <img src={avatarUrl} alt="Avatar" className="w-24 h-24 rounded-full" />}
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    You can update these settings later in your user profile.
                  </p>
                </div>
              ) : (
                <div className="space-y-4">
                  <h4 className="text-md font-medium text-gray-700 dark:text-gray-300">You've been enrolled in our Free tier</h4>
                  <p className="text-gray-600 dark:text-gray-400">
                    You now have {userData?.num_requests || 20} scans available to start exploring our service.
                  </p>
                  {renderApiKey()}
                  <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300">Benefits of our service:</h5>
                  <div className="flex items-center text-gray-600 dark:text-gray-400">
                    <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                    <p>Advanced AI-powered URL analysis</p>
                  </div>
                  <div className="flex items-center text-gray-600 dark:text-gray-400">
                    <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                    <p>Comprehensive security checks</p>
                  </div>
                  <div className="flex items-center text-gray-600 dark:text-gray-400">
                    <FontAwesomeIcon icon={faBolt} className="mr-2" />
                    <p>Instant results</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {step === 1 ? (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleNextStep}
              >
                Next
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleComplete}
                >
                  Start Scanning
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleViewPricing}
                >
                  View Pricing
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeLoginModal;
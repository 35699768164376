import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../utils/supabaseClient';
import { ThemeContext } from '../utils/ThemeContext';

const SignupModal = ({ isOpen, onClose, onSwitchToLogin, onSignupComplete }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const { theme } = useContext(ThemeContext);

  const handleGoogleSignup = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin // Ensure this redirects back to your app
        }
      });
      if (error) throw error;
      if (onSignupComplete && typeof onSignupComplete === 'function') {
        onSignupComplete(); // Call this when signup is successful
      }
    } catch (error) {
      console.error('Error during Google signup:', error);
      setMessage(error.error_description || error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLinkSignup = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log('Sending magic link to:', email);
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (error) throw error;

      setMessage('Check your email for the magic link!');
      setMessageType('success');
      if (onSignupComplete && typeof onSignupComplete === 'function') {
        onSignupComplete(); // Call this when signup is successful
      }
    } catch (error) {
      console.error('Error during magic link signup:', error);
      setMessage(error.error_description || error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${theme}`}>
      <div className="relative w-full max-w-md p-8 bg-base-100 rounded-lg shadow-xl">
        <button onClick={onClose} className="absolute top-2 right-2 text-base-content opacity-70 hover:opacity-100">
          &times;
        </button>
        <div className="flex justify-center mb-8">
          <img className="h-8 w-auto" src={theme === 'dark' ? "/logo-dark.svg" : "/logo-light.svg"} alt="Your Logo" />
        </div>
        <h2 className="text-3xl font-bold mb-8 text-center text-base-content">
          Create your account
        </h2>
        {message && (
          <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-error'} mb-4`}>
            <span>{message}</span>
          </div>
        )}
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleGoogleSignup}
            disabled={loading}
            className="btn btn-outline"
          >
            <div className="flex items-center justify-center">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
              </svg>
              Continue with Google
            </div>
          </button>
          <div className="divider text-base-content opacity-70">OR</div>
          <div className="form-control relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content opacity-70 z-10">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input input-bordered w-full pl-10 bg-base-200 text-base-content"
            />
          </div>
          <button
            onClick={handleMagicLinkSignup}
            disabled={loading}
            className="btn btn-primary"
          >
            Sign up with Magic Link
          </button>
        </div>
        <p className="mt-4 text-center text-base-content opacity-70">
          Already have an account?{' '}
          <button
            onClick={onSwitchToLogin}
            className="text-primary hover:underline"
          >
            Log in
          </button>
        </p>
      </div>
    </div>
  );
};

export default SignupModal;
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import SignupModal from './SignupModal';
import { AuthContext } from '../utils/AuthContext';
import { motion } from 'framer-motion';
import { supabase } from '../utils/supabaseClient';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MotionDiv = motion.div;

const PricingTier = React.memo(({ name, price, features, negatives, ctaText, onCtaClick, isComingSoon, isCurrentPlan, isLoggedIn, isDowngrade }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonText = () => {
    if (isLoggedIn && !isComingSoon) {
      return isDowngrade ? "Downgrade" : "Upgrade";
    }
    return ctaText;
  };

  const buttonClass = () => {
    if (isComingSoon) return 'btn-disabled';
    if (isDowngrade) return 'btn-warning';
    return 'btn-primary'; // Always use btn-primary for upgrades
  };

  return (
    <MotionDiv
      className={`card ${isCurrentPlan ? 'bg-primary text-primary-content' : 'bg-base-100'} shadow-xl`}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="card-body">
        <h2 className={`card-title ${isCurrentPlan ? 'text-primary-content' : ''}`}>{name}</h2>
        <p className={`text-3xl font-bold ${isCurrentPlan ? 'text-primary-content' : ''}`}>{price}</p>
        <ul className="space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <FontAwesomeIcon icon={faCheck} className={`mr-2 ${isCurrentPlan ? 'text-primary-content' : 'text-success'}`} />
              <span className={isCurrentPlan ? 'text-primary-content' : ''}>{feature}</span>
            </li>
          ))}
          {negatives && negatives.map((negative, index) => (
            <li key={`neg-${index}`} className={`flex items-center ${isCurrentPlan ? 'text-primary-content opacity-80' : 'text-base-content opacity-70'}`}>
              <FontAwesomeIcon icon={faTimes} className={`mr-2 ${isCurrentPlan ? 'text-primary-content' : 'text-error'}`} />
              {negative}
            </li>
          ))}
        </ul>
        <div className="card-actions justify-end mt-4">
          {isCurrentPlan ? (
            <div className="badge badge-secondary badge-lg">Current Plan</div>
          ) : (
            <button 
              className={`btn ${buttonClass()}`}
              onClick={onCtaClick}
              disabled={isComingSoon}
            >
              {buttonText()}
            </button>
          )}
        </div>
      </div>
    </MotionDiv>
  );
});

const PricingPage = () => {
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const { session } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

  useEffect(() => {
    fetchPlans();
    if (session) {
      fetchUserPlan();
    }
  }, [session]);

  const fetchPlans = async () => {
    const { data, error } = await supabase
      .from('plans')
      .select('id, name, price, interval, stripe_price_id, features, negatives, scan_limit')
      .order('price', { ascending: true });
    if (error) {
      console.error('Error fetching plans:', error);
    } else {
      setPlans(data);
    }
  };

  const fetchUserPlan = async () => {
    if (session) {
      const { data, error } = await supabase
        .from('users')
        .select('plan_id')
        .eq('id', session.user.id)
        .single();

      if (error) {
        console.error('Error fetching user plan:', error);
      } else {
        setUserPlan(data.plan_id);
        await fetchCurrentPlanDetails(data.plan_id);
      }
    }
  };

  const fetchCurrentPlanDetails = async (planId) => {
    const { data, error } = await supabase
      .from('plans')
      .select('id, name, price, interval, scan_limit')
      .eq('id', planId)
      .single();

    if (error) {
      console.error('Error fetching current plan details:', error);
    } else {
      setCurrentPlanDetails(data);
    }
  };

  const handlePlanClick = async (plan) => {
    if (session) {
      try {
        // Make sure we're using the correct stripe_price_id
        const priceId = plan.stripe_price_id;
        
        if (!priceId) {
          throw new Error('Invalid plan selected');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            priceId: priceId,
            successUrl: `${window.location.origin}/success`,
            cancelUrl: `${window.location.origin}/pricing`,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to create checkout session');
        }

        const { sessionId } = await response.json();
        
        // Redirect to Stripe Checkout
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        await stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    } else {
      setIsSignupModalOpen(true);
    }
  };

  const handleFreeTierClick = async () => {
    if (session) {
      try {
        const freeTierPlanId = 'fd5938fb-a785-4330-9e50-29bec31c6533';
        const { data: freeTierPlan, error: planError } = await supabase
          .from('plans')
          .select('scan_limit')
          .eq('id', freeTierPlanId)
          .single();

        if (planError) throw planError;

        const { data, error } = await supabase
          .from('users')
          .update({ plan_id: freeTierPlanId, num_requests: freeTierPlan.scan_limit })
          .eq('id', session.user.id);

        if (error) throw error;

        setUserPlan(freeTierPlanId);
        fetchCurrentPlanDetails(freeTierPlanId);
      } catch (error) {
        console.error('Error activating free tier:', error);
      }
    } else {
      setOpenLoginModal(true);
    }
};

  const handleContactSalesClick = () => {
    window.location.href = "mailto:dan@aiurl.io?subject=Interested in Custom Solution for AI URL Scanner";
  };

  const handleCheckoutSuccess = async () => {
    setIsCheckoutModalOpen(false);
    
    // Refresh user plan information
    await fetchUserPlan();
    
    // toast({
    //   title: "Subscription Updated",
    //   description: `You've successfully subscribed to the ${selectedPlan.name} plan.`,
    //   status: "success",
    //   duration: 5000,
    //   isClosable: true,
    // });
  };

  const isPlanDowngrade = (planPrice) => {
    if (!currentPlanDetails) return false;
    return planPrice < currentPlanDetails.price;
  };

  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Pricing</title>
        <meta name="description" content="Choose the perfect plan for your URL scanning needs." />
      </Helmet>
      <Header openLoginModalExternal={openLoginModal} />
      
      <main className="container mx-auto py-16 px-4">
        <div className="text-center space-y-8">
          <div>
            <h1 className="text-4xl font-bold mb-4">Choose Your Plan</h1>
            <p className="text-xl text-base-content opacity-70 max-w-2xl mx-auto">
              Select the perfect plan for your needs. All plans include our advanced AI-powered URL analysis.
            </p>
          </div>

          {session && currentPlanDetails && (
            <div className="alert alert-info">
              <FontAwesomeIcon icon={faExclamationCircle} />
              <span>You are currently on the {currentPlanDetails.name} plan. You have {currentPlanDetails.scan_limit} scans per {currentPlanDetails.interval}.</span>
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.filter(plan => plan.price > 0).map((plan) => (
              <PricingTier 
                key={plan.id}
                name={plan.name}
                price={`$${plan.price}/${plan.interval}`}
                features={plan.features}
                negatives={plan.negatives}
                ctaText={session ? 'Upgrade' : 'Sign Up'}
                onCtaClick={() => handlePlanClick(plan)}
                isComingSoon={false}
                isCurrentPlan={userPlan === plan.id}
                isLoggedIn={!!session}
                isDowngrade={isPlanDowngrade(plan.price)}
              />
            ))}
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
            {plans.filter(plan => plan.price === 0).map((plan) => (
              <PricingTier 
                key={plan.id}
                name={plan.name}
                price="Free"
                features={plan.features}
                negatives={plan.negatives}
                ctaText={session ? 'Activate Free Tier' : 'Sign Up For Free'}
                onCtaClick={session ? handleFreeTierClick : () => setIsSignupModalOpen(true)}
                isComingSoon={false}
                isCurrentPlan={userPlan === plan.id}
                isLoggedIn={!!session}
                isDowngrade={currentPlanDetails && currentPlanDetails.price > 0}
              />
            ))}
            <MotionDiv
              className="card bg-base-100 shadow-xl"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="card-body">
                <h2 className="card-title">Custom Solution</h2>
                <p className="text-3xl font-bold">Contact Us</p>
                <ul className="space-y-2">
                  {[
                    'Customizable scan volume',
                    'Dedicated scanning environment',
                    'API access for automation',
                    'Full reports with AI summaries',
                    'Malicious/Safe decision',
                    'Webhook access for SIEM/SOAR integration',
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-success mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className="card-actions justify-end mt-4">
                  <button 
                    className="btn btn-primary"
                    onClick={handleContactSalesClick}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </MotionDiv>
          </div>
        </div>
      </main>

      <Footer />
      <SignupModal 
        isOpen={isSignupModalOpen} 
        onClose={() => setIsSignupModalOpen(false)}
        onSwitchToLogin={() => {
          setIsSignupModalOpen(false);
          navigate('/login');
        }}
      />
    </div>
  );
};

export default PricingPage;
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { motion } from 'framer-motion';

const MotionDiv = motion.div;

const TermsCard = ({ title, content }) => (
  <MotionDiv
    className="card bg-base-100 shadow-xl"
    whileHover={{ scale: 1.05 }}
    transition={{ duration: 0.3 }}
  >
    <div className="card-body">
      <h3 className="card-title text-base-content">{title}</h3>
      <p className="text-base-content/70">{content}</p>
    </div>
  </MotionDiv>
);

const TermsOfServicePage = () => {
  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Terms of Service</title>
        <meta name="description" content="Terms of Service for AI URL Scanner" />
      </Helmet>
      <Header />
      
      <main className="flex-grow">
        <div className="bg-base-100 py-16">
          <div className="container mx-auto px-4">
            <div className="space-y-8">
              <h1 className="text-4xl font-bold text-center text-base-content">
                Terms of Service
              </h1>
              <p className="text-lg text-center text-base-content/70">
                By using aiurl.io, you agree to comply with and be bound by the following terms and conditions of use.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <TermsCard
                  title="Acceptance of Terms"
                  content="By accessing or using our service, you agree to be bound by these Terms of Service and all applicable laws and regulations."
                />
                <TermsCard
                  title="Use of Service"
                  content="You agree to use our service only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website."
                />
                <TermsCard
                  title="User Accounts"
                  content="You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account."
                />
                <TermsCard
                  title="Intellectual Property"
                  content="The content, organization, graphics, design, and other matters related to aiurl.io are protected under applicable copyrights and other proprietary laws."
                />
                <TermsCard
                  title="Limitation of Liability"
                  content="aiurl.io and its affiliates will not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of or inability to use the service."
                />
                <TermsCard
                  title="Modifications to Service"
                  content="We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice at any time."
                />
              </div>

              <div className="space-y-6 mt-12">
                <h2 className="text-2xl font-bold text-base-content">Governing Law</h2>
                <p className="text-base-content/70">
                  These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                </p>

                <h2 className="text-2xl font-bold text-base-content">Changes to Terms</h2>
                <p className="text-base-content/70">
                  We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes to these Terms on our website.
                </p>

                <h2 className="text-2xl font-bold text-base-content">Contact Us</h2>
                <p className="text-base-content/70">
                  If you have any questions about these Terms, please contact us at terms@aiurl.io.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default TermsOfServicePage;
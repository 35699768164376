import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading } from '@fortawesome/free-solid-svg-icons';

const HeaderDetails = ({ headers }) => {
  let parsedHeaders;
  try {
    parsedHeaders = typeof headers === 'string' ? JSON.parse(headers) : headers;
  } catch (error) {
    console.error("Error parsing headers:", error);
    return <p className="text-error">Error parsing headers</p>;
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faHeading} className="mr-2" aria-hidden="true" /> Header Details
        </h2>
        <div className="max-h-96 overflow-y-auto">
          <div className="space-y-2">
            {Object.entries(parsedHeaders).map(([key, value]) => (
              <div key={key}>
                <p className="font-bold text-base-content">{key}:</p>
                <p className="pl-4 whitespace-pre-wrap break-words text-base-content opacity-70">
                  {typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDetails;
const API_ENDPOINT = 'https://api.aiurl.io/analyze'; // This will be replaced with the actual server endpoint

export const analyzeUrl = async (url, userId, isPrivate) => {
  try {
    //console.log('urlAnalysisService: Sending request with:', { url, userId, isPrivate });
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url, userId, isPrivate }),
    });

    if (!response.ok) {
      console.error('urlAnalysisService: Network response was not ok', response.status, response.statusText);
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    //console.log('urlAnalysisService: Received response:', data);
    return data.jobId;
  } catch (error) {
    console.error('urlAnalysisService: Error:', error);
    throw error;
  }
};
import React, { useContext, useState, useCallback } from 'react';
import { ReactFlow, Controls, Background, useNodesState, useEdgesState, getBezierPath, Handle, Position } from '@xyflow/react';
import { ThemeContext } from '../../utils/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faSkull, faServer, faGlobe, faExclamationTriangle, faInfoCircle, faChevronDown, faChevronUp, faProjectDiagram, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import HTTPTransactionsTable from './HTTPTransactionsTable';
import { useNavigate, useLocation } from 'react-router-dom';

import '@xyflow/react/dist/style.css';

const NetworkFlow = ({ scanData }) => {
  const { theme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Update the initial activeTab state based on the URL hash
  const [activeTab, setActiveTab] = useState(() => {
    return location.hash === '#http-transactions' ? 'httpTransactions' : 'networkFlow';
  });

  const getUriPath = (url, maxLength = 40) => {
    try {
      const parsedUrl = new URL(url);
      let path = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
      if (path.length > maxLength) {
        path = path.substring(0, maxLength - 3) + '...';
      }
      return path;
    } catch (error) {
      console.error('Invalid URL:', url);
      return '';
    }
  };

  const getSuspiciousUrls = () => {
    
    const urls = scanData.html_analysis?.analysis?.suspicious_urls;
    
    if (Array.isArray(urls)) {
      return urls;
    } else if (typeof urls === 'string') {
      return [urls];
    }
    return [];
  };

  const suspiciousUrls = getSuspiciousUrls();

  const calculateNodePositions = () => {
    const mainPosition = { x: 50, y: 250 };
    const suspiciousStartX = 800;
    const suspiciousStartY = 50;
    const verticalSpacing = 100;

    return {
      main: mainPosition,
      suspicious: suspiciousUrls.map((_, index) => ({
        x: suspiciousStartX,
        y: suspiciousStartY + index * verticalSpacing
      }))
    };
  };

  const nodePositions = calculateNodePositions();

  const getRiskAssessment = (riskScore, processingStatus) => {
    if (processingStatus === "ERROR") {
      return { color: 'border-error' };
    } else if (riskScore === null || riskScore === undefined) {
      return { color: 'border-info' };
    } else if (riskScore > 75) {
      return { color: 'border-error' };
    } else if (riskScore >= 35) {
      return { color: 'border-warning' };
    } else {
      return { color: 'border-success' };
    }
  };

  const getRiskScore = () => {
    if (scanData.ai_analysis && typeof scanData.ai_analysis === 'object') {
      if (typeof scanData.ai_analysis.risk_score === 'number') {
        return scanData.ai_analysis.risk_score;
      }
      if (typeof scanData.ai_analysis.analysis === 'string') {
        try {
          const analysisString = scanData.ai_analysis.analysis.replace(/```json|```/g, '').trim();
          const parsedAnalysis = JSON.parse(analysisString);
          if (typeof parsedAnalysis.risk_score === 'number') {
            return parsedAnalysis.risk_score;
          }
        } catch (error) {
          console.error("Error parsing ai_analysis:", error);
        }
      }
    }
    return scanData.final_score;
  };

  const riskScore = getRiskScore();
  const riskAssessment = getRiskAssessment(riskScore, scanData.processing_status);

  const getGeoDataString = (geoData) => {
    if (!geoData) {
      return 'Unknown, Unknown';
    }
    
    let parsedGeoData;
    try {
      parsedGeoData = typeof geoData === 'string' ? JSON.parse(geoData) : geoData;
    } catch (error) {
      console.error('Error parsing geoData:', error);
      return 'Unknown, Unknown';
    }
    
    if (!Array.isArray(parsedGeoData)) {
      parsedGeoData = [parsedGeoData];
    }
    
    if (parsedGeoData.length === 0) {
      return 'Unknown, Unknown';
    }
    
    const locationStrings = parsedGeoData.map(location => 
      `${location.country || 'Unknown'}, ${location.city || 'Unknown'} (${location.ip})`
    );

    // Group locations into pairs
    const groupedLocations = [];
    for (let i = 0; i < locationStrings.length; i += 2) {
      groupedLocations.push(locationStrings.slice(i, i + 2).join(' | '));
    }

    // Join the grouped locations with line breaks
    return groupedLocations.join('\n');
  };

  const createSuspiciousNodes = () => {
    const validSuspiciousUrls = suspiciousUrls.map(url => {
      // If it's a full URL, return as is
      if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
      }
      // If it starts with '//', add 'https:'
      if (url.startsWith('//')) {
        return 'https:' + url;
      }
      // If it starts with '/', it's a relative path
      if (url.startsWith('/')) {
        return new URL(url, scanData.original_url).href;
      }
      // If it contains a dot and doesn't start with a slash, it might be a file or subdomain
      if (url.includes('.') && !url.startsWith('/')) {
        // Check if it looks like a domain (e.g., subdomain.example.com)
        if (url.includes('/') || url.split('.').length > 2) {
          return 'https://' + url;
        }
        // Otherwise, treat it as a file path relative to the original URL
        return new URL(url, scanData.original_url).href;
      }
      // For everything else, assume it's a relative path
      return new URL(url, scanData.original_url).href;
    }).filter(url => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    });

    if (validSuspiciousUrls.length <= 5) {
      return validSuspiciousUrls.map((url, index) => ({
        id: `suspicious-${index}`,
        type: 'turbo',
        position: nodePositions.suspicious[index],
        data: {
          title: (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning mr-2" />
              Suspicious URL
            </div>
          ),
          subline: url,
          tooltip: "Suspicious URL",
        },
      }));
    } else {
      return [{
        id: 'suspicious-group',
        type: 'turbo',
        position: nodePositions.suspicious[0],
        data: {
          title: (
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning mr-2" />
                Suspicious URLs ({validSuspiciousUrls.length})
              </div>
              <button onClick={() => setIsExpanded(!isExpanded)} className="ml-2">
                <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
              </button>
            </div>
          ),
          urls: validSuspiciousUrls,
          isExpanded: isExpanded,
          tooltip: "Suspicious URLs Identified",
        },
      }];
    }
  };

  const isAlive = (status) => {
    if (typeof status === 'boolean') {
      return status;
    }
    if (typeof status === 'string') {
      return status.toLowerCase() === 'alive' || status.toLowerCase() === 'true';
    }
    // If status is undefined, check if processing_status is "Completed" and status is "success load"
    if (status === undefined && scanData.processing_status === "Completed" && scanData.status === "success load") {
      return true;
    }
    return false;
  };

  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: 'original',
      type: 'turbo',
      position: nodePositions.main,
      data: {
        title: (
          <div className="flex items-center">
            <FontAwesomeIcon 
              icon={isAlive(scanData.alive) ? faHeartbeat : faSkull} 
              className={isAlive(scanData.alive) ? "text-success mr-2" : "text-error mr-2"} 
            />
            {scanData.parsed_domain || new URL(scanData.original_url).hostname}
          </div>
        ),
        subline: getUriPath(scanData.original_url),
        details: [
          scanData.title ? { icon: faInfoCircle, text: scanData.title } : null,
          scanData.web_server ? { icon: faServer, text: scanData.web_server } : null,
          scanData.geo_data ? { icon: faGlobe, text: getGeoDataString(scanData.geo_data) } : null,
        ].filter(Boolean),
        borderColor: riskAssessment.color,
        tooltip: "Scanned URL Details",
        fullUri: scanData.original_url,
      },
    },
    ...createSuspiciousNodes(),
  ]);

  const createEdges = () => {
    if (suspiciousUrls.length <= 5) {
      return suspiciousUrls.map((_, index) => ({
        id: `e-suspicious-${index}`,
        source: 'original',
        target: `suspicious-${index}`,
        sourceHandle: 'right',
        targetHandle: 'left',
        type: 'wavy',
      }));
    } else {
      return [{
        id: 'e-suspicious-group',
        source: 'original',
        target: 'suspicious-group',
        sourceHandle: 'right',
        targetHandle: 'left',
        type: 'wavy',
      }];
    }
  };

  const [edges, setEdges, onEdgesChange] = useEdgesState(createEdges());

  const onNodeClick = useCallback((event, node) => {
    if (node.id === 'suspicious-group') {
      setIsExpanded(!isExpanded);
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === 'suspicious-group') {
            return {
              ...n,
              data: { ...n.data, isExpanded: !isExpanded },
            };
          }
          return n;
        })
      );
    }
  }, [isExpanded, setNodes]);

  const nodeTypes = {
    turbo: TurboNode,
  };

  const edgeTypes = {
    turbo: TurboEdge,
    squiggly: SquigglyEdge,
    wavy: WavyEdge,
  };

  const httpTransactions = scanData.http_transactions ? JSON.parse(scanData.http_transactions) : [];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'httpTransactions') {
      navigate(`${location.pathname}#http-transactions`, { replace: true });
    } else {
      navigate(location.pathname, { replace: true });
    }
  };

  return (
    <div className="hidden md:block">
      <div className="tabs tabs-boxed mb-4">
        <a 
          className={`tab ${activeTab === 'networkFlow' ? 'tab-active' : ''}`}
          onClick={() => handleTabChange('networkFlow')}
        >
          <FontAwesomeIcon icon={faProjectDiagram} className="mr-2" />
          Suspicious Indicator Flow
        </a>
        <a 
          className={`tab ${activeTab === 'httpTransactions' ? 'tab-active' : ''}`}
          onClick={() => handleTabChange('httpTransactions')}
        >
          <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
          HTTP Transactions
        </a>
      </div>
      <div className="bg-base-200 rounded-lg shadow-lg">
        {activeTab === 'networkFlow' ? (
          <div style={{ height: '500px' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeClick={onNodeClick}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              fitView
              fitViewOptions={{ padding: 0.2, includeHiddenNodes: true }}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        ) : (
          <div className="p-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <HTTPTransactionsTable transactions={httpTransactions} />
          </div>
        )}
      </div>
    </div>
  );
};

const TurboNode = ({ data }) => {
  return (
    <>
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="target" position={Position.Left} id="left" />
      <div 
        className={`bg-base-100 rounded-lg shadow-lg p-4 ${data.borderColor ? `border-2 ${data.borderColor}` : ''}`}
        title={data.tooltip}
      >
        <div className="flex flex-col">
          {data.title && (
            <div className="flex items-center text-sm mb-2">
              <span className="font-semibold">{data.title}</span>
            </div>
          )}
          {data.subline && (
            <p className="text-sm text-base-content/60 mb-2" title={data.fullUri}>
              {data.subline}
            </p>
          )}
          {data.details && (
            <div>
              {data.details.map((detail, index) => (
                <div key={index} className="flex items-center text-sm mb-1">
                  <FontAwesomeIcon icon={detail.icon} className="mr-2" />
                  <span style={{ whiteSpace: 'pre-line' }}>{detail.text}</span>
                </div>
              ))}
            </div>
          )}
          {data.urls && (
            <div className={`mt-2 ${data.isExpanded ? '' : 'hidden'}`}>
              <ul className="mt-1 text-xs max-h-40 overflow-y-auto">
                {data.urls.map((url, index) => (
                  <li key={index} className="mb-1">{url}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const TurboEdge = ({ id, sourceX, sourceY, targetX, targetY, style = {} }) => {
  const edgePath = `M ${sourceX} ${sourceY} C ${(sourceX + targetX) / 2} ${sourceY} ${(sourceX + targetX) / 2} ${targetY} ${targetX} ${targetY}`;

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path stroke-primary stroke-2"
      d={edgePath}
    />
  );
};

const SquigglyEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const d = `${edgePath} q 20 20 40 0 t 40 0`;

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path stroke-primary stroke-2"
      d={d}
      markerEnd={markerEnd}
    />
  );
};

const WavyEdge = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const edgePath = `M${sourceX},${sourceY} C ${sourceX + 100},${sourceY} ${targetX - 100},${targetY} ${targetX},${targetY}`;

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path stroke-error stroke-2"
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
};

export default NetworkFlow;
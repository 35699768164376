import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  const fetchReleaseNotes = async () => {
    try {
      const { data, error } = await supabase
        .from('release_notes')
        .select('version, release_date, content')
        .order('release_date', { ascending: false });

      if (error) throw error;
      setReleaseNotes(data);
    } catch (error) {
      console.error('Error fetching release notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-primary" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Release Notes</h2>
      <div className="bg-base-200 p-6 rounded-lg shadow-md">
        {releaseNotes.map((note, index) => (
          <React.Fragment key={note.id}>
            {index > 0 && (
              <hr className="my-8 border-t border-base-300 dark:border-base-600" />
            )}
            <div className="mb-8 last:mb-0">
              <h3 className="text-xl font-semibold mb-2">{note.version}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                Released on {formatDate(note.release_date)}
              </p>
              <div className="markdown-content">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{note.content}</ReactMarkdown>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ReleaseNotes;

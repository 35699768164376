import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShieldAlt, faRobot, faBolt, faExternalLinkAlt, faSpinner, faChevronRight, faCheckCircle, faExclamationTriangle, faTimesCircle, faQuestionCircle, faSkull, faHeartbeat, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import FirstTimeLoginModal from './FirstTimeLoginModal';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { analyzeUrl } from '../services/urlAnalysisService';
import DOMPurify from 'dompurify';
import { Turnstile } from '@marsidev/react-turnstile';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

const LandingPage = () => {
  const { session, userData, setUserData } = useContext(AuthContext);
  const [url, setUrl] = useState('');
  const [isFirstTimeLoginModalOpen, setIsFirstTimeLoginModalOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [error, setError] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const navigate = useNavigate();
  const [isTurnstileVisible, setIsTurnstileVisible] = useState(false);
  const turnstileRef = useRef(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [recentScans, setRecentScans] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Check if it's the user's first login
    if (userData && userData.first_login) {
      setIsFirstTimeLoginModalOpen(true);
    }

    // Fetch scans immediately when the component mounts
    fetchRecentScans();

    // Set up the interval to fetch scans every 15 seconds
    intervalRef.current = setInterval(fetchRecentScans, 15000);

    // Clean up the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [userData]); // Added userData as a dependency

  const fetchRecentScans = async () => {
    const { data, error } = await supabase
      .from('ripper_details')
      .select('job_id, original_url, parsed_domain, final_score, alive, ai_analysis->risk_score')
      .order('timestamp', { ascending: false })
      .limit(5);

    if (error) {
      console.error('Error fetching recent scans:', error);
    } else {
      setRecentScans(prevScans => {
        const newScans = data.filter(scan => !prevScans.some(prevScan => prevScan.job_id === scan.job_id));
        return [...newScans, ...prevScans].slice(0, 5);
      });
    }
  };

  const truncateUrl = (url, maxLength = 60) => {
    if (!url) return '';
    if (url.length <= maxLength) return url;
    return `${url.slice(0, maxLength)}...`;
  };

  const getRiskScore = (scan) => {
    if (scan.risk_score !== null && scan.risk_score !== undefined) {
      return scan.risk_score;
    }
    return scan.final_score || null;
  };

  const getRiskColor = (scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'bg-gray-100 text-gray-800';
    if (score > 75) return 'bg-red-100 text-red-800';
    if (score >= 35) return 'bg-yellow-100 text-yellow-800';
    return 'bg-green-100 text-green-800';
  };

  const getRiskIcon = (scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return faQuestionCircle;
    if (score >= 70) return faExclamationCircle;
    if (score >= 35) return faExclamationTriangle;
    return faCheckCircle;
  };

  const getRiskText = (scan) => {
    const score = getRiskScore(scan);
    if (score === null || score === undefined) return 'N/A';
    if (score > 75) return 'High';
    if (score >= 35) return 'Moderate';
    return 'Low';
  };

  const getStatusIcon = (scan) => {
    if (scan.alive === 'alive') return faHeartbeat;
    if (scan.alive === 'not alive') return faSkull;
    return faQuestionCircle;
  };

  const getStatusColor = (scan) => {
    if (scan.alive === 'alive') return 'text-green-600 dark:text-green-400';
    if (scan.alive === 'not alive') return 'text-red-600 dark:text-red-400';
    return 'text-gray-600 dark:text-gray-400';
  };

  const getStatusTooltip = (scan) => {
    if (scan.alive === 'alive') return 'WAS_LIVE';
    if (scan.alive === 'not alive') return 'WAS_DEAD';
    return 'UNKNOWN';
  };

  const getStatusMessage = (jobStatus) => {
    switch (jobStatus) {
      case 'Starting':
        return "Starting up...";
      case 'Checking':
        return "Checking URL safety...";
      case 'Gathering':
        return "Gathering DNS, SSL, and IP info...";
      case 'Capturing':
        return "Capturing screenshot...";
      case 'Processing':
        return "Analyzing with AI...";
      case 'Finalizing':
        return "Finalizing and completing the analysis...";
      case 'Completed':
        return "Completed";
      default:
        return "Processing, please allow up to 1 minute...";
    }
  };

  const sanitizeUrl = (input) => {
    // Use DOMPurify to remove any HTML tags
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    
    // More permissive URL validation pattern
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/[\w\-.~!$&'()*+,;=:@/%?#]*)?$/i;
    if (urlPattern.test(sanitized)) {
      return sanitized;
    } else {
      return ''; // Return empty string for invalid URLs
    }
  };

  const handleUrlChange = (e) => {
    // Allow typing by setting the raw input value
    setUrl(e.target.value);
  };

  const handleScan = async () => {
    if (!isTurnstileVisible) {
      setIsTurnstileVisible(true);
      return;
    }

    try {
      setError(null);
      setIsLoading(true);

      const sanitizedUrl = sanitizeUrl(url);
      if (!sanitizedUrl) {
        setError('Please enter a valid URL.');
        setIsLoading(false);
        return;
      }

      //console.log('LandingPage: Starting scan with:', { sanitizedUrl, userId: session.user.id, isPrivate });
      const jobId = await analyzeUrl(sanitizedUrl, session.user.id, isPrivate);
      //console.log('LandingPage: Received jobId:', jobId);
      setJobId(jobId);

      if (userData.num_requests <= 0) {
        setError('You have no scans left. Please wait for the next reset.');
        setIsLoading(false);
        return;
      }

      const newRequestCount = userData.num_requests - 1;

      const { error } = await supabase
        .from('users')
        .update({ num_requests: newRequestCount })
        .eq('id', session.user.id);

      if (error) {
        console.error('LandingPage: Error updating request count:', error);
        setError('Error updating request count. Please try again.');
      } else {
        setUserData({ ...userData, num_requests: newRequestCount });
      }

      // Navigate to ResultsPage immediately after starting the scan
      navigate(`/results/${jobId}`);
    } catch (error) {
      console.error('LandingPage: Error scanning URL:', error);
      setError('Error scanning URL. Please try again.');
    } finally {
      setIsLoading(false);
      setIsTurnstileVisible(false);
      turnstileRef.current?.reset();
    }
  };

  const onTurnstileSuccess = async (token) => {
    // Proceed with the scan
    await handleScan();
  };

  const closeFirstTimeLoginModal = () => {
    setIsFirstTimeLoginModalOpen(false);
  };

  const handleFirstTimeLoginComplete = async (name, avatarUrl) => {
    // Handle the completion of the first-time login setup
    const { error } = await supabase
      .from('users')
      .update({ name, avatar_url: avatarUrl, first_login: false })
      .eq('id', session.user.id);

    if (error) {
      console.error('Error updating user data:', error);
      setError('Failed to update your profile. Please try again.');
      return;
    }

    // Update the local userData state
    setUserData({ ...userData, name, avatar_url: avatarUrl, first_login: false });

    // Close the modal
    closeFirstTimeLoginModal();
  };

  const handleInputFocus = () => {
    if (!session) {
      setOpenLoginModal(true);
    }
  };

  // Reset openLoginModal when session changes
  useEffect(() => {
    if (session) {
      setOpenLoginModal(false);
    }
  }, [session]);

  const handleUrlClick = (jobId) => {
    navigate(`/results/${jobId}`);
  };

  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Home</title>
        <meta name="description" content="Scan URLs for potential risks and threats using our AI-powered scanner." />
        <meta name="keywords" content="URL scanner, AI scanner, website security, threat detection" />
      </Helmet>
      <Header openLoginModalExternal={openLoginModal} />
      
      <main className="flex-grow">
        <div className="bg-base-100 py-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center space-y-8">
              <div className="space-y-2">
                <h1 className="text-4xl font-bold text-base-content">
                  Advanced Phishing Site Detection
                </h1>
                <p className="text-xl text-base-content opacity-70" role="presentation">
                  Comprehensive URL analysis powered by AI and heuristics
                </p>
              </div>
              
              <div className="w-full max-w-3xl">
                <div className="space-y-4">
                  {session && (
                    <div className="flex justify-center items-center">
                      <div className="tooltip" data-tip="Free accounts are limited to 20 scans per month. Premium options with additional scans will be available soon.">
                        <span className={`badge ${userData?.num_requests <= 5 ? 'badge-error' : 'badge-success'} p-3 text-sm`}>
                          Scans Remaining: {userData?.num_requests || 0}
                        </span>
                      </div>
                      <div className="divider divider-horizontal"></div>
                      <div className="form-control">
                        <label className="label cursor-pointer">
                          <span className="label-text mr-2">Private</span> 
                          <input type="checkbox" className="toggle toggle-primary" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />
                        </label>
                      </div>
                    </div>
                  )}
                  
                  <div className="flex flex-col items-center w-full">
                    <div className="join w-full">
                      <input 
                        type="text"
                        placeholder="Enter URL to scan"
                        value={url}
                        onChange={handleUrlChange}
                        onFocus={handleInputFocus}
                        disabled={isLoading}
                        className="input input-bordered join-item w-full"
                      />
                      <button
                        onClick={handleScan}
                        disabled={isLoading || url.trim() === ''}
                        className="btn btn-primary join-item"
                      >
                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                        Scan
                      </button>
                    </div>
                    
                    {isTurnstileVisible && (
                      <div className="mt-4">
                        <Turnstile
                          ref={turnstileRef}
                          siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                          onSuccess={onTurnstileSuccess}
                          onError={() => setError('Turnstile verification failed. Please try again.')}
                          onExpire={() => setIsTurnstileVisible(false)}
                          options={{
                            theme: 'light',
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {isLoading && (
                    <div className="w-full">
                      <progress className="progress progress-primary w-full"></progress>
                      <p className="mt-2 text-primary">{getStatusMessage(jobStatus)}</p>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Recent Scans section */}
              <div className="w-full max-w-3xl mt-8">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-base-content">
                    Recent Scans
                  </h2>
                  <div className="flex items-center">
                    <span className="text-sm text-base-content opacity-70 mr-2">
                      refreshes every 15 seconds
                    </span>
                    <FontAwesomeIcon icon={faSpinner} spin className="text-base-content opacity-70" />
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th className="text-xs sm:text-sm">URL</th>
                        <th className="text-xs sm:text-sm text-center hidden sm:table-cell">Domain</th>
                        <th className="text-xs sm:text-sm text-center">Risk</th>
                        <th className="text-xs sm:text-sm text-center hidden sm:table-cell">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <AnimatePresence initial={false}>
                        {recentScans.map((scan) => (
                          <motion.tr
                            key={scan.job_id}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.3 }}
                          >
                            <td className="text-xs sm:text-sm p-2 sm:p-4">
                              <span 
                                className="cursor-pointer hover:underline"
                                onClick={() => handleUrlClick(scan.job_id)}
                              >
                                {truncateUrl(scan.original_url, window.innerWidth < 640 ? 20 : 40)}
                              </span>
                              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1 text-xs sm:text-sm text-base-content opacity-50" />
                            </td>
                            <td className="text-xs sm:text-sm p-2 sm:p-4 text-center hidden sm:table-cell">
                              {truncateUrl(scan.parsed_domain || 'N/A', 25)}
                            </td>
                            <td className="text-center p-2 sm:p-4">
                              <span className={`badge ${getRiskColor(scan)} text-2xs sm:text-xs whitespace-nowrap px-2 sm:px-3 py-1 sm:py-2`}>
                                {getRiskText(scan)}
                              </span>
                            </td>
                            <td className="p-2 sm:p-4 text-center hidden sm:table-cell">
                              <span 
                                className={`inline-flex items-center justify-center w-5 h-5 sm:w-6 sm:h-6 rounded-full ${getStatusColor(scan)}`}
                                title={getStatusTooltip(scan)}
                              >
                                <FontAwesomeIcon icon={getStatusIcon(scan)} className="text-xs sm:text-sm" />
                              </span>
                            </td>
                          </motion.tr>
                        ))}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
                <div className="text-right mt-4">
                  <RouterLink to="/recent-scans" className="text-sm sm:text-base font-medium text-base-content opacity-70 hover:opacity-100">
                    See more <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
                  </RouterLink>
                </div>
              </div>
              
              <div className="divider"></div>
              
              {error && (
                <div className="alert alert-error">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <span>{error}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="py-12 bg-base-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-primary font-semibold tracking-wide uppercase">Why Choose aiurl.io</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-base-content sm:text-4xl">
                Comprehensive Phishing Detection
              </p>
              <p className="mt-4 max-w-2xl text-xl text-base-content opacity-70 lg:mx-auto">
                Our cutting-edge technology combines multiple heuristic methods with AI analysis to provide accurate and reliable results.
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-primary-content">
                      <FontAwesomeIcon icon={faShieldAlt} className="h-6 w-6" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-base-content">30+ heuristics</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-base-content opacity-70">Comprehensive analysis using multiple herustic methods</dd>
                </div>

                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-primary-content">
                      <FontAwesomeIcon icon={faRobot} className="h-6 w-6" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-base-content">AI-Powered Analysis</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-base-content opacity-70">Advanced machine learning algorithms for accurate results</dd>
                </div>

                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-primary-content">
                      <FontAwesomeIcon icon={faBolt} className="h-6 w-6" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-base-content">Instant Results</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-base-content opacity-70">Get immediate feedback on the safety of any URL</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </main>

      <Footer />
      <FirstTimeLoginModal
        isOpen={isFirstTimeLoginModalOpen}
        onClose={closeFirstTimeLoginModal}
        onComplete={handleFirstTimeLoginComplete}
        userData={userData}
      />
    </div>
  );
};

export default LandingPage;
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-base-200 text-base-content">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-10 w-auto" src="/logo-dark.svg" alt="AIURL Scanner" />
            <p className="text-base">
              Scan. Protect. Surf Safe.
            </p>
            <div className="flex space-x-6">
              <a href="#" className="text-base-content hover:text-primary">
                <span className="sr-only">Facebook</span>
                <FontAwesomeIcon icon={faFacebookF} className="h-6 w-6" />
              </a>
              <a href="#" className="text-base-content hover:text-primary">
                <span className="sr-only">Twitter</span>
                <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
              </a>
              <a href="#" className="text-base-content hover:text-primary">
                <span className="sr-only">LinkedIn</span>
                <FontAwesomeIcon icon={faLinkedinIn} className="h-6 w-6" />
              </a>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-base-content tracking-wider uppercase">Solutions</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="#" className="text-base hover:text-primary">URL Scanning</a></li>
                  <li><a href="#" className="text-base hover:text-primary">Phishing Detection</a></li>
                  <li><a href="#" className="text-base hover:text-primary">AI Analysis</a></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-base-content tracking-wider uppercase">Support</h3>
                <ul className="mt-4 space-y-4">
                  <li><Link to="/pricing" className="text-base hover:text-primary">Pricing</Link></li>
                  <li><a href="#" className="text-base hover:text-primary">Documentation</a></li>
                  <li><a href="#" className="text-base hover:text-primary">API Status</a></li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-base-content tracking-wider uppercase">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="#" className="text-base hover:text-primary">About</a></li>
                  <li><a href="#" className="text-base hover:text-primary">Blog</a></li>
                  <li><a href="#" className="text-base hover:text-primary">Careers</a></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-base-content tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li><Link to="/privacy" className="text-base hover:text-primary">Privacy Policy</Link></li>
                  <li><Link to="/terms" className="text-base hover:text-primary">Terms of Service</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-base-300 pt-8">
          <p className="text-base text-base-content opacity-70 xl:text-center">
            &copy; {currentYear} AIURL Scanner. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faCodeBranch, faCreditCard, faClipboardList } from '@fortawesome/free-solid-svg-icons';

const SettingsNav = ({ activePage, setActivePage }) => {
  const [, forceUpdate] = useState();
  const activePageRef = useRef(activePage);

  useEffect(() => {
    if (activePageRef.current !== activePage) {
      activePageRef.current = activePage;
      forceUpdate({});
    }
  }, [activePage]);

  const navItems = [
    { id: 'details', icon: faUser, text: 'User Details' },
    { id: 'api', icon: faKey, text: 'API Key' },
    { id: 'webhook', icon: faCodeBranch, text: 'Webhooks' },
    { id: 'subscription', icon: faCreditCard, text: 'Subscription' },
    { id: 'release-notes', icon: faClipboardList, text: 'Release Notes' },
  ];

  return (
    <nav className="bg-base-200 rounded-lg p-4 shadow-md">
      <ul className="menu menu-vertical w-full p-0">
        {navItems.map((item) => (
          <li key={item.id} className="mb-2">
            <button
              onClick={() => setActivePage(item.id)}
              className={`w-full text-left px-4 py-2 rounded-lg transition-colors duration-200 ${
                activePage === item.id
                  ? 'bg-primary text-primary-content hover:bg-primary hover:text-primary-content'
                  : 'text-base-content hover:bg-base-300'
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-2" />
              {item.text}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default React.memo(SettingsNav);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLinkAlt, faGlobe, faExclamationTriangle, faPercent, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const RelatedScanCard = ({ scan, currentJarmFingerprint }) => {
  const getRiskColor = (score) => {
    if (score <= 33) return 'text-success';
    if (score <= 66) return 'text-warning';
    return 'text-error';
  };

  const getSimilarityColor = (similarity) => {
    const percentage = similarity * 100;
    if (percentage <= 25) return 'text-info';
    if (percentage <= 50) return 'text-accent';
    if (percentage <= 75) return 'text-warning';
    return 'text-secondary';
  };

  const isJarmMatch = scan.jarm_fingerprint === currentJarmFingerprint;

  const truncateUrl = (url, maxLength = 30) => {
    if (url.length <= maxLength) return url;
    const protocol = url.startsWith('https://') ? 'https://' : 'http://';
    const domain = url.replace(protocol, '').split('/')[0];
    const truncated = domain.length > maxLength - 3 ? domain.substr(0, maxLength - 6) + '...' : domain;
    return protocol + truncated;
  };

  return (
    <div className="card bg-base-100 shadow-xl mb-4 w-full">
      <div className="card-body">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={faLink} className="mr-2 text-base-content flex-shrink-0" />
          <h3 className="text-sm font-semibold text-base-content truncate flex-grow" title={scan.original_url}>
            {truncateUrl(scan.original_url, 30)}
          </h3>
        </div>
        <div className="space-y-1">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faGlobe} className="mr-2 text-base-content" />
            <p className="text-base-content text-sm">Domain: {scan.parsed_domain}</p>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className={`mr-2 ${getRiskColor(scan.risk_score)}`} />
            <p className="text-base-content text-sm">Risk Score: <span className={getRiskColor(scan.risk_score)}>{scan.risk_score}</span></p>
          </div>
          {scan.similarity && (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPercent} className={`mr-2 ${getSimilarityColor(scan.similarity)}`} />
              <p className="text-base-content text-sm">Similarity: <span className={getSimilarityColor(scan.similarity)}>{Math.round(scan.similarity * 100)}%</span></p>
            </div>
          )}
          {isJarmMatch && (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFingerprint} className="mr-2 text-success" />
              <p className="text-success text-sm">JARM Fingerprint Match</p>
            </div>
          )}
        </div>
        {scan.screenshot_url && (
          <img
            src={scan.screenshot_url}
            alt={`Screenshot of ${scan.original_url}`}
            className="w-full h-24 object-cover rounded-md my-2"
          />
        )}
        <Link
          to={`/results/${scan.job_id}`}
          className="btn btn-primary btn-sm w-full mt-2"
        >
          View Details
          <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
        </Link>
      </div>
    </div>
  );
};

const RelatedScans = ({ relatedScans, currentJarmFingerprint }) => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-base-content">
          <FontAwesomeIcon icon={faLink} className="mr-2" aria-hidden="true" /> Related Scans
        </h2>
        {relatedScans.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {relatedScans.map((scan) => (
              <RelatedScanCard key={scan.id} scan={scan} currentJarmFingerprint={currentJarmFingerprint} />
            ))}
          </div>
        ) : (
          <p className="text-base-content opacity-70">No related scans found.</p>
        )}
      </div>
    </div>
  );
};

export default RelatedScans;
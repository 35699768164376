import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { ThemeContext } from '../utils/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLink, faExclamationCircle, faExternalLinkAlt, faCopy, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Breadcrumbs from './Breadcrumbs';
import ScanHero from './results/ScanHero';
import SecurityInfo from './results/SecurityInfo';
import SuspiciousIndicators from './results/SuspiciousIndicators';
import NetworkInformation from './results/NetworkInformation';
import DomainInformation from './results/DomainInformation';
import AIAnalysis from './results/AIAnalysis';
import HeaderDetails from './results/HeaderDetails';
import RelatedScans from './results/RelatedScans';
import Screenshot from './results/Screenshot';
import HTMLAnalysis from './results/HTMLAnalysis';
import RawHTML from './results/RawHTML';
import LoginModal from './LoginModal';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from './NotFoundPage';
import NetworkFlow from './results/NetworkFlow';

// Register the HTML language
SyntaxHighlighter.registerLanguage('html', html);

const getRiskAssessment = (riskScore) => {
  if (riskScore === null || riskScore === undefined) {
    return {
      title: 'Unknown',
      color: 'text-blue-700 dark:text-blue-300',
      bgColor: 'bg-blue-50 dark:bg-blue-900/20',
      icon: faInfoCircle,
      scoreColor: 'text-blue-600 dark:text-blue-400'
    };
  } else if (riskScore > 80) {
    return {
      title: 'High Risk Detected',
      color: 'text-red-700 dark:text-red-300',
      bgColor: 'bg-red-50 dark:bg-red-900/20',
      icon: faExclamationTriangle,
      scoreColor: 'text-red-600 dark:text-red-400'
    };
  } else if (riskScore >= 40) {
    return {
      title: 'Moderate Risk Detected',
      color: 'text-yellow-700 dark:text-yellow-300',
      bgColor: 'bg-yellow-50 dark:bg-yellow-900/20',
      icon: faExclamationTriangle,
      scoreColor: 'text-yellow-600 dark:text-yellow-400'
    };
  } else {
    return {
      title: 'Low Risk Detected',
      color: 'text-green-700 dark:text-green-300',
      bgColor: 'bg-green-50 dark:bg-green-900/20',
      icon: faInfoCircle,
      scoreColor: 'text-green-600 dark:text-green-400'
    };
  }
};

const ResultsPage = ({ showErrorInfo }) => {
  const { jobId } = useParams();
  const [results, setResults] = useState(null);
  const [childScans, setChildScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { session, userData } = useContext(AuthContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [relatedScans, setRelatedScans] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isProcessing, setIsProcessing] = useState(true);
  const [completedSections, setCompletedSections] = useState({});
  const [aiAnalysisStatus, setAiAnalysisStatus] = useState('loading');
  const [htmlAnalysisStatus, setHtmlAnalysisStatus] = useState('loading');
  const [isSiteAlive, setIsSiteAlive] = useState(null);
  const [scanStatus, setScanStatus] = useState(null);
  const [initialDataReceived, setInitialDataReceived] = useState(false);
  const [activeTab, setActiveTab] = useState('original');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [scanUser, setScanUser] = useState(null);
  const { theme } = useContext(ThemeContext);
  const [toast, setToast] = useState(null);

  //console.log('ResultsPage showErrorInfo:', showErrorInfo);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setToast({
          message: 'Link copied to clipboard',
          type: 'success'
        });
        setTimeout(() => setToast(null), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        setToast({
          message: `Failed to copy link: ${err.message}`,
          type: 'error'
        });
        setTimeout(() => setToast(null), 3000);
      });
  }, []);

  const fetchRelatedScans = useCallback(async (originalUrl, parsedDomain, jarmFingerprint) => {
    try {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      const { data, error } = await supabase
        .from('ripper_details')
        .select('id, job_id, original_url, parsed_domain, final_score, timestamp, ai_analysis->risk_score, jarm_fingerprint')
        .gt('timestamp', thirtyDaysAgo.toISOString())
        .not('job_id', 'eq', jobId)
        .order('timestamp', { ascending: false })
        .limit(100);  // Increased limit for better filtering

      if (error) throw error;

      const filteredData = data
        .map(scan => {
          const scanUrl = new URL(scan.original_url);
          const domainSimilarity = calculateDomainSimilarity(new URL(originalUrl).hostname, scanUrl.hostname);
          const structureSimilarity = calculateStructureSimilarity(new URL(originalUrl), scanUrl);
          const jarmSimilarity = calculateJarmSimilarity(jarmFingerprint, scan.jarm_fingerprint);
          
          // Prioritize JARM matches
          const overallSimilarity = jarmSimilarity === 1 ? 1 : (
            domainSimilarity * 0.3 + 
            structureSimilarity * 0.3 + 
            jarmSimilarity * 0.4
          );

          return { 
            ...scan, 
            similarity: overallSimilarity,
            risk_score: scan.risk_score || scan.final_score
          };
        })
        .filter(scan => scan.similarity > 0.3)  // Lowered threshold
        .sort((a, b) => b.similarity - a.similarity)
        .slice(0, 5);

      setRelatedScans(filteredData);
    } catch (error) {
      console.error('Error fetching related scans:', error);
    }
  }, [jobId]);

  const calculateJarmSimilarity = (jarm1, jarm2) => {
    if (!jarm1 || !jarm2) return 0;
    return jarm1 === jarm2 ? 1 : 0;  // Simplified to exact matches only
  };

  const calculateDomainSimilarity = (domain1, domain2) => {
    const parts1 = domain1.split('.');
    const parts2 = domain2.split('.');
    let similarity = 0;
    
    // Compare TLD and domain
    if (parts1[parts1.length - 1] === parts2[parts2.length - 1]) similarity += 0.3;
    if (parts1[parts1.length - 2] === parts2[parts2.length - 2]) similarity += 0.3;
    
    // Compare subdomains
    const subdomains1 = parts1.slice(0, -2).join('.');
    const subdomains2 = parts2.slice(0, -2).join('.');
    similarity += calculateSimilarity(subdomains1, subdomains2) * 0.4;
    
    return similarity;
  };

  const calculateStructureSimilarity = (url1, url2) => {
    const structure1 = url1.pathname.split('/').map(p => p.replace(/[0-9a-f-]+/gi, '{id}'));
    const structure2 = url2.pathname.split('/').map(p => p.replace(/[0-9a-f-]+/gi, '{id}'));
    return calculateSimilarity(structure1.join('/'), structure2.join('/'));
  };

  const calculateSimilarity = (str1, str2) => {
    if (!str1 || !str2) return 0;
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  };

  const editDistance = (str1, str2) => {
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();
    const costs = new Array();
    for (let i = 0; i <= str1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= str2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else if (j > 0) {
          let newValue = costs[j - 1];
          if (str1.charAt(i - 1) !== str2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
      if (i > 0) {
        costs[str2.length] = lastValue;
      }
    }
    return costs[str2.length];
  };

  const fetchResults = async () => {
    try {
      while (true) {
        const { data, error } = await supabase
          .from('ripper_details')
          .select(`
            id,
            job_id,
            original_url,
            parsed_domain,
            timestamp,
            final_score,
            alive,
            status,
            processing_status,
            web_server,
            safebrowsing,
            cert_details,
            jarm_fingerprint,
            suspicious_indicators,
            ip_addresses,
            whois,
            mx_records,
            headers,
            screenshot_url,
            raw_html,
            ai_analysis,
            html_analysis,
            linked_user,
            geo_data,
            is_private,
            title,
            http_transactions,
            form_details
          `)
          .eq('job_id', jobId)
          .single();

        if (error) {
          // Handle Supabase error
          console.error('Error fetching results:', error);
          if (error.message === 'JSON object requested, multiple (or no) rows returned') {
            // Redirect to NotFoundPage if jobId doesn't exist
            navigate('/not-found');
          } else {
            setError('Failed to fetch results. Please try again.');
            setInitialDataReceived(true);
          }
          setLoading(false);
          return;
        }

        setResults(data);
        
        // Instead of fetching scan user data separately, we can use the linked_user id
        // to get the user details from the users table if needed
        if (data.linked_user) {
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('id, name, avatar_url')
            .eq('id', data.linked_user)
            .single();

          if (!userError) {
            setScanUser(userData);
          }
        }

        setIsSiteAlive(data.alive === 'alive');
        setScanStatus(data.status);
        setInitialDataReceived(true);

        setCompletedSections(prevSections => ({
          ...prevSections,
          scanHero: true,
          securityInfo: !!(data.web_server || data.safebrowsing || data.cert_details || data.jarm_fingerprint),
          suspiciousIndicators: !!data.suspicious_indicators,
          networkInformation: !!data.ip_addresses,
          domainInformation: !!(data.whois || data.mx_records),
          headerDetails: !!data.headers,
          screenshot: !!data.screenshot_url,
          rawHtml: !!data.raw_html
        }));

        // Check if processing_status is 'ERROR'
        if (data.processing_status === 'ERROR') {
          setIsProcessing(false);
          break;
        }

        setIsProcessing(data.processing_status !== 'Completed');

        if (data.processing_status === 'Completed') {
          setAiAnalysisStatus(data.ai_analysis ? 'completed' : 'not_available');
          setHtmlAnalysisStatus(data.html_analysis && typeof data.html_analysis === 'object' && data.html_analysis.analysis ? 'completed' : 'not_available');

          fetchRelatedScans(data.original_url, data.parsed_domain, data.jarm_fingerprint);

          const { data: childData, error: childError } = await supabase
            .from('ripper_details')
            .select(`
              id, 
              job_id, 
              original_url, 
              timestamp, 
              final_score, 
              status,
              processing_status,
              headers,
              screenshot_url,
              raw_html,
              html_analysis,
              ai_analysis,
              suspicious_indicators,
              ip_addresses,
              whois,
              mx_records,
              safebrowsing,
              web_server,
              cert_details,
              jarm_fingerprint,
              geo_data
            `)
            .eq('parent_id', data.id);

          if (childError) throw childError;

          setChildScans(childData);
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to fetch results. Please try again.');
      setInitialDataReceived(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [jobId, session, navigate]);

  useEffect(() => {
    if (results) {
      fetchRelatedScans(results.original_url, results.parsed_domain, results.jarm_fingerprint);
    }
  }, [results, fetchRelatedScans]);

  const hasDiscoveredUrls = childScans.length > 0;

  const getRiskBadge = (riskScore) => {
    if (riskScore === null || riskScore === undefined) {
      return null;
    }
    if (riskScore >= 75) {
      return (
        <span className="ml-2 badge badge-sm badge-error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
        </span>
      );
    } else if (riskScore >= 50) {
      return (
        <span className="ml-2 badge badge-sm badge-warning">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
          Medium Risk
        </span>
      );
    } else if (riskScore >= 25) {
      return (
        <span className="ml-2 badge badge-sm badge-info">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
          Low Risk
        </span>
      );
    }
    return null;
  };

  const truncateUrl = (url, maxLength = 30) => {
    try {
      const parsedUrl = new URL(url);
      let displayUrl = parsedUrl.hostname + parsedUrl.pathname;
      
      if (displayUrl.length <= maxLength) {
        return displayUrl;
      }
      
      // Truncate the path
      const pathLength = Math.max(maxLength - parsedUrl.hostname.length - 3, 0);
      const truncatedPath = parsedUrl.pathname.substring(0, pathLength) + '...';
      
      return parsedUrl.hostname + truncatedPath;
    } catch (error) {
      // If URL parsing fails, fall back to simple truncation
      return url.length > maxLength ? url.substring(0, maxLength - 3) + '...' : url;
    }
  };

  useEffect(() => {
    if (!hasDiscoveredUrls) {
      setActiveTab('original');
      return;
    }

    const hash = location.hash.slice(1);
    if (hash.startsWith('discovered')) {
      const index = parseInt(hash.slice(10)) - 1;
      if (index >= 0 && index < childScans.length) {
        setActiveTab(`child-${index}`);
      }
    } else {
      setActiveTab('original');
    }
  }, [location.hash, childScans.length, hasDiscoveredUrls]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'original') {
      navigate(`/results/${jobId}`, { replace: true });
    } else {
      const index = parseInt(tab.split('-')[1]) + 1;
      navigate(`/results/${jobId}#discovered${index}`, { replace: true });
    }
  };

  const getRiskColor = (score) => {
    if (score < 30) return 'bg-success text-success-content';
    if (score < 70) return 'bg-warning text-warning-content';
    return 'bg-error text-error-content';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getAlertStatus = (classification) => {
    const lowerCaseClassification = classification.toLowerCase();
    if (lowerCaseClassification.includes('not malicious')) {
      return 'success';
    } else if (lowerCaseClassification.includes('malicious')) {
      return 'error';
    }
    return 'warning';
  };

  const getResizedImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return null;
    return `${originalUrl}/fullsize`;
  }, []);

  const getFullResultsUrl = useCallback(() => {
    return `${window.location.origin}/results/${jobId}`;
  }, [jobId]);

  const fallbackImageUrl = 'https://hxrbmdbkeevaoorhpzhd.supabase.co/storage/v1/object/public/assets/warn.jpeg?t=2024-09-18T23%3A34%3A36.691Z';

  const getMetaTitle = () => `AI URL Scanner | ${results?.original_url || 'Scan Results'}`;

  const getMetaDescription = () => {
    if (!results) return 'Loading scan results...';
    return `Scan results for ${results.original_url}. Risk assessment: ${getRiskAssessment(
      results.final_score
    ).title}. Classification: ${results.ai_analysis?.classification || 'Not available'}. ${
      results.ai_analysis?.analysis?.summary || ''
    }`.slice(0, 300); // Truncate to 300 characters
  };

  const getMetaKeywords = () => 'URL scanner, scan results, website security, threat detection, phishing detection';

  const renderScanContent = (scanData, index = null) => {
    if (!scanData) {
      return <div>No scan data available</div>;
    }

    const isAlive = (status) => {
      if (typeof status === 'boolean') {
        return status;
      }
      if (typeof status === 'string') {
        return status.toLowerCase() === 'alive' || status.toLowerCase() === 'true';
      }
      // If status is undefined, check if processing_status is "Completed" and status is "success load"
      if (status === undefined && scanData.processing_status === "Completed" && scanData.status === "success load") {
        return true;
      }
      return false;
    };

    const aliveStatus = isAlive(scanData.alive);

    if (scanData.processing_status === 'ERROR') {
      return (
        <ScanHero
          scanData={scanData}
          copyToClipboard={copyToClipboard}
          scanUser={scanUser}
          showErrorInfo={showErrorInfo}
        />
      );
    }

    // New condition to check if status is 'Legitimate Domain'
    if (scanData.status === 'Legitimate Domain') {
      return (
        <ScanHero
          scanData={scanData}
          copyToClipboard={copyToClipboard}
          scanUser={scanUser}
          showErrorInfo={showErrorInfo}
        />
      );
    }

    const renderNotAliveMessage = (title, description) => (
      <div className="alert alert-warning shadow-lg mb-6" role="alert">
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} className="stroke-current flex-shrink-0 h-6 w-6" />
          <div>
            <h3 className="font-bold">{title}</h3>
            <div className="text-xs">{description}</div>
          </div>
        </div>
      </div>
    );

    const renderSkeletonLoader = (title) => (
      <div className="card bg-base-100 shadow-xl mb-6" aria-busy="true" aria-label={`Loading ${title}`}>
        <div className="card-body">
          <h2 className="card-title">{title}</h2>
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-base-300 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-base-300 rounded"></div>
              <div className="h-4 bg-base-300 rounded w-5/6"></div>
            </div>
          </div>
          <p className="mt-4 font-bold text-center">Analyzing...</p>
        </div>
      </div>
    );

    return (
      <>
        {isProcessing && (
          <div className="flex flex-col items-center justify-center my-8" aria-live="polite">
            <div className="loading loading-spinner loading-lg"></div>
            <p className="mt-4 text-base-content/60">
              Analyzing the URL, please wait...
            </p>
          </div>
        )}

        {completedSections.scanHero ? (
          <ScanHero 
            scanData={{...scanData, alive: aliveStatus}}
            copyToClipboard={copyToClipboard}
            scanUser={scanUser}
            showErrorInfo={showErrorInfo}
          />
        ) : (
          <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}

        {/* Add the NetworkFlow component here */}
        {scanData && scanData.original_url && (
          <div className="mb-6">
            <NetworkFlow scanData={{
              ...scanData,
              alive: aliveStatus,
              additional_urls: scanData.additional_urls ? JSON.parse(scanData.additional_urls) : []
            }} />
          </div>
        )}

        {isSiteAlive === false && renderNotAliveMessage(
          "Site Not Alive",
          "The site was not alive at the time of the scan. Some information may be limited or unavailable."
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {completedSections.securityInfo ? (
            <SecurityInfo scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("No Security Info", "Security information is not available for sites that were not alive during the scan.")
          ) : (
            <div className="skeleton h-48 w-full"></div>
          )}

          {completedSections.suspiciousIndicators ? (
            <SuspiciousIndicators scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("No Suspicious Indicators", "Suspicious indicator information is not available for sites that were not alive during the scan.")
          ) : (
            <div className="skeleton h-48 w-full"></div>
          )}

          {completedSections.networkInformation ? (
            <NetworkInformation scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("Limited Network Information", "Detailed network information may be limited for sites that were not alive during the scan.")
          ) : (
            <div className="skeleton h-48 w-full"></div>
          )}

          {completedSections.domainInformation ? (
            <DomainInformation scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("Limited Domain Information", "Some domain information may still be available even though the site was not alive during the scan.")
          ) : (
            <div className="skeleton h-48 w-full"></div>
          )}
        </div>

        <div className="mb-6">
          {!initialDataReceived ? (
            renderSkeletonLoader("AI Analysis")
          ) : isSiteAlive === false ? (
            renderNotAliveMessage(
              "AI Analysis Unavailable",
              "AI analysis could not be performed as the site was not responsive during the scan."
            )
          ) : isProcessing || aiAnalysisStatus === 'loading' ? (
            renderSkeletonLoader("AI Analysis")
          ) : (
            <AIAnalysis scanData={scanData} />
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {scanData.headers ? (
            <HeaderDetails headers={scanData.headers} />
          ) : (
            <div className="alert alert-warning shadow-lg">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} className="stroke-current flex-shrink-0 h-6 w-6" />
                <div>
                  <h3 className="font-bold">No Header Details</h3>
                  <div className="text-xs">Header details are not available for this scan.</div>
                </div>
              </div>
            </div>
          )}

          <RelatedScans relatedScans={relatedScans} getRiskColor={getRiskColor} />
        </div>

        {completedSections.screenshot ? (
          <div className="mb-6">
            <Screenshot scanData={scanData} />
          </div>
        ) : isSiteAlive === false ? (
          renderNotAliveMessage("No Screenshot", "Screenshot is not available for sites that were not alive during the scan.")
        ) : (
          <div className="h-96 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}

        <div className="mb-6">
          {!initialDataReceived ? (
            renderSkeletonLoader("HTML Analysis")
          ) : isSiteAlive === false ? (
            renderNotAliveMessage(
              "HTML Analysis Unavailable",
              "HTML analysis could not be performed as the site was not responsive during the scan."
            )
          ) : isProcessing || htmlAnalysisStatus === 'loading' ? (
            renderSkeletonLoader("HTML Analysis")
          ) : (
            <HTMLAnalysis htmlAnalysis={scanData.html_analysis} finalScore={scanData.final_score} />
          )}
        </div>

        {completedSections.rawHtml ? (
          <div className="mb-6">
            <RawHTML rawHtml={scanData.raw_html} formDetails={scanData.form_details} />
          </div>
        ) : isSiteAlive === false ? (
          renderNotAliveMessage("No Raw HTML", "Raw HTML is not available for sites that were not alive during the scan.")
        ) : (
          <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}
      </>
    );
  };

  if (loading || isProcessing) return (
    <div className={`min-h-screen bg-base-100 ${theme}`}>
      <Helmet>
        <title>{getMetaTitle()}</title>
        <meta name="description" content={getMetaDescription()} />
        <meta name="keywords" content={getMetaKeywords()} />
        
        {/* Open Graph meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getFullResultsUrl()} />
        <meta property="og:title" content={getMetaTitle()} />
        <meta property="og:description" content={getMetaDescription()} />
        <meta property="og:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={getFullResultsUrl()} />
        <meta name="twitter:title" content={getMetaTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />
      </Helmet>
      <Header />
      <main className="container mx-auto py-10 px-4">
        <Breadcrumbs items={[
          { href: '/', label: 'Home' },
          { href: '/recent-scans', label: 'Recent Scans' },
          { href: '#', label: `${jobId}` },
        ]} />
        {renderScanContent(results || {})}
      </main>
      <Footer />
    </div>
  );

  if (error) return (
    <div className={`min-h-screen bg-base-100 ${theme}`}>
      <Helmet>
        <title>{getMetaTitle()}</title>
        <meta name="description" content={getMetaDescription()} />
        <meta name="keywords" content={getMetaKeywords()} />
        
        {/* Open Graph meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getFullResultsUrl()} />
        <meta property="og:title" content={getMetaTitle()} />
        <meta property="og:description" content={getMetaDescription()} />
        <meta property="og:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={getFullResultsUrl()} />
        <meta name="twitter:title" content={getMetaTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />
      </Helmet>
      <Header />
      <div className="container mx-auto py-10 px-4">
        <div className="max-w-lg mx-auto">
          {error === 'You must be logged in to view private scan results.' ? (
            <div className="alert alert-error">
              <FontAwesomeIcon icon={faExclamationCircle} className="stroke-current flex-shrink-0 h-6 w-6" />
              <span>{error}</span>
              <button className="btn btn-sm btn-primary" onClick={() => setIsLoginModalOpen(true)}>
                Log in
              </button>
            </div>
          ) : (
            <div className="text-error text-center">{error}</div>
          )}
        </div>
      </div>
      <Footer />
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
    </div>
  );

  if (!results) return <NotFoundPage />;

  return (
    <div className={`min-h-screen bg-base-100 ${theme}`}>
      <Helmet>
        <title>{getMetaTitle()}</title>
        <meta name="description" content={getMetaDescription()} />
        <meta name="keywords" content={getMetaKeywords()} />
        
        {/* Open Graph meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getFullResultsUrl()} />
        <meta property="og:title" content={getMetaTitle()} />
        <meta property="og:description" content={getMetaDescription()} />
        <meta property="og:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={getFullResultsUrl()} />
        <meta name="twitter:title" content={getMetaTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={results?.screenshot_url ? getResizedImageUrl(results.screenshot_url) : fallbackImageUrl} />
      </Helmet>

      <Header />
      <main className="container mx-auto py-10 px-4">
        <Breadcrumbs items={[
          { href: '/', label: 'Home' },
          { href: '/recent-scans', label: 'Recent Scans' },
          { href: '#', label: `${jobId}` },
        ]} />
        
        {hasDiscoveredUrls && (
          <div className="mb-6 overflow-x-auto">
            <div role="tablist" className="tabs tabs-boxed flex-nowrap">
              <button
                role="tab"
                className={`tab ${activeTab === 'original' ? 'tab-active' : ''} whitespace-nowrap`}
                onClick={() => handleTabChange('original')}
              >
                <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                <span className="hidden sm:inline">Original URL</span>
                <span className="sm:hidden">Original</span>
                {getRiskBadge(results.final_score)}
              </button>
              {childScans.map((child, index) => (
                <button
                  key={child.id}
                  role="tab"
                  className={`tab ${activeTab === `child-${index}` ? 'tab-active' : ''} whitespace-nowrap`}
                  onClick={() => handleTabChange(`child-${index}`)}
                  title={child.original_url}
                >
                  <FontAwesomeIcon icon={faLink} className="mr-2 hidden sm:inline" />
                  <span className="hidden sm:inline">{truncateUrl(child.original_url, 25)}</span>
                  <span className="sm:hidden">
                    <FontAwesomeIcon icon={faLink} className="mr-1" />
                    {index + 1}
                  </span>
                  {getRiskBadge(child.final_score)}
                </button>
              ))}
            </div>
          </div>
        )}

        {(!hasDiscoveredUrls || activeTab === 'original') && renderScanContent(results)}
        {hasDiscoveredUrls && childScans.map((child, index) => (
          activeTab === `child-${index}` && renderScanContent(child, index)
        ))}
      </main>
      <Footer />
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
      {toast && (
        <div className={`alert ${toast.type === 'success' ? 'alert-success' : 'alert-error'} fixed bottom-4 right-4 w-auto`}>
          <div>
            <span>{toast.message}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;